import React, { useState, useContext } from 'react';
import UserContext from '../../../../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ExportApi from "../../../../../api/ExportApi";

export default function FavouriteItem({
  brand,
  thumbnail,
  title,
  series,
  model,
  price,
  id,
  data,
  chipset,
  condition
}) {
  const [isClicked, setIsClicked] = useState(false);
  const { selectedProducts, setSelectedProducts, newlySelectedProducts, setNewlySelectedProducts } = useContext(UserContext);
  const navigate = useNavigate();
  const userId = JSON.parse(localStorage.getItem("tokenuser"))?.id;
  const token = localStorage.getItem("token"); 

  // To remove items from Favourite
  const handleClick = (e) => {
    e.preventDefault();
    setIsClicked(isClicked);
    e.preventDefault();
    if(!isClicked === false) {
      ExportApi.AddtoFevreat(id ,userId)
      //   productId: id
      // }, {
      //   headers: {
      //     Authorization: `Bearer ${token}`
      //   }
      // },)
        .then(function (response) {
          console.log("Favourite", response);
          
          setIsClicked(false)
          ExportApi.favouriteList(userId)
          //   headers: {
          //     Authorization: `Bearer ${token}`
          //   },
          // })
          .then(function (response) {
            console.log("favourite List", response.data.data.favourite)
            setNewlySelectedProducts(response.data.data.favourite);
          })
          .catch(function (error) {
              console.log(error);
          })
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    else {
      ExportApi.RemovetoFevreat(id ,userId)
      //   productId: id
      // }, {
      //   headers: {
      //     Authorization: `Bearer ${token}`
      //   }
      // },)
        .then(function (response) {
          console.log("Favourite Remove", response);
          ExportApi.favouriteList(userId)
          //   headers: {
          //     Authorization: `Bearer ${token}`
          //   },
          // })
          .then(function (response) {
            console.log("favourite List", response.data.data.favourite)
            setNewlySelectedProducts(response.data.data.favourite);
          })
          .catch(function (error) {
              console.log(error);
          })
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  // Add products to compare
  const handleCheckboxChange = () => {
    const isSelected = selectedProducts.some((product) => product._id === data._id);

    if (isSelected) {
      setSelectedProducts((prevProducts) =>
        prevProducts.filter((product) => product._id !== data._id)
      );
    } else {
      setSelectedProducts([...selectedProducts, data]);
    }
  };


  // Favourite svg style
  const svgStyle = {
    width: '20px',
    height: '20px',
    fill: isClicked ? 'transparent' : 'red',
    stroke: 'black',
    strokeWidth: isClicked ? '1px' : 0,
    cursor: 'pointer',
  };

  return (
    <div className="mb-3 col-sm-6 col-md-3 col-xl-2 col-12 position-relative" key={id}>
      {console.log("my favourite data 123")}
      <div className="product-box p-3 position-relative">
        <span className='d-flex position-absolute' style={{ color: chipset === 'AMD' ? 'rgb(186, 0, 21)' : chipset === 'Nvidia' ? 'rgb(114, 179, 0)' : 'inherit' }}> {chipset}</span>
        <div className='d-flex flex-sm-column mt-2 mt-xl-3 w-100'>
          <div onClick={() => navigate(`/product/${id}`)}>
            <img src={thumbnail} className='m-sm-auto' alt="thumbnail" />
          </div>
          <div className="product-details d-flex justify-content-between w-100 pt-3 ms-2 ms-md-0">
            <div className="desc w-100" 
            // onClick={() => navigate(`/product/${id}`)}
            >
              <p className='text-start mb-0'>{title}</p>
              <p className='text-start mb-0'>{brand}</p>
              <p className='text-start mb-0'>{series}</p>
              <p className='text-start mb-0'>{model}</p>
              <p className='text-start fw-bold condition mb-1'>Condition: {condition === 1 ? "New-Retail" : condition === 2 ? "New-2nd Hand" : "Used"}</p>
              <h5 className='text-center fw-bold mt-0 mb-1 mt-lg-0'>{price}</h5>
            </div>
            <svg
              viewBox="0 0 17 17"
              className="sc-beqWaB ixusxy hear-icon"
              xmlns="http://www.w3.org/2000/svg"
              style={svgStyle}
              onClick={(e) => {handleClick(e)}}
            >
              <path fillRule="evenodd" d="M8.5,2.3C12.9-2.2,24,5.7,8.5,16C-7,5.7,4.1-2.2,8.5,2.3z"></path>
            </svg>
          </div>
          <div className="compare text-end">
            <label htmlFor={`compare-${data._id}`}>Compare</label>
            <input
              type="checkbox"
              id={`compare-${data._id}`}
              checked={selectedProducts.some((product) => product._id === data._id)}
              onChange={handleCheckboxChange}
              className='ms-2'
            />
          </div>
        </div>
      </div>
    </div>
  );
}