import React, { useEffect, useState, useContext } from 'react';
import '../../products.css';
import FavouriteItem from '../favouritesItem/FavouriteItem';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import UserContext from '../../../../../context/UserContext';
import { toggleSelectedProduct } from '../../../../../context/UserContext';

export default function FavouriteList({ apiEndpoint }) {
  const [productCheckedState, setProductCheckedState] = useState({});
  const { selectedProducts, setSelectedProducts, newlySelectedProducts } = useContext(UserContext);
  const [tooltipVisible, setTooltipVisible] = useState({
    popUp: false,
    id: null
  });
  const navigate = useNavigate();

  const handleMouseEnter = (id) => {
    setTooltipVisible({
      ...tooltipVisible,
      popUp: true,
      id: id


    })
  };

  const handleMouseLeave = () => {
    setTooltipVisible({
      ...tooltipVisible,
      popUp: false,
      id: null


    })
  };

  // Add Products in Compare Section
  const handleCheckboxChange = (productId) => {
    toggleSelectedProduct(productId, selectedProducts, setSelectedProducts);
  };

  // Remove a Single Product from Compare Section
  const handleRemoveProduct = (productId) => {
    setSelectedProducts((prevSelectedProducts) =>
      prevSelectedProducts.filter((product) => product.id !== productId)
    );

    setProductCheckedState((prevState) => {
      const updatedState = { ...prevState };
      delete updatedState[productId];
      return updatedState;
    });
  };

  // Remove all Products from Compare Section
  const handleClearAll = () => {
    setSelectedProducts([]);
  }
  const handleClick = (id) => {
   
    setTooltipVisible({
          ...tooltipVisible,
          popUp: true,
          id: id
    
    
        })
};

  return (
    <>
    {console.log("Checking Popup: ", tooltipVisible)}
    <div className='deals p-0'>
      <div className="row products-list m-0 justify-content-start">
        {newlySelectedProducts?.map((data) => (
          <FavouriteItem
            key={data._id}
            chipset={data.chipset}
            id={data._id}
            thumbnail={data.image[0]}
            title={data.title}
            series={data.series}
            brand={data.brand}
            price={data.type == 1 ? `$${data?.new_retail_website_price}` : (data?.lowest_ask > 0 ? `$${data?.lowest_ask}` : <>
              <button className="askbidbtn custom-btn border rounded mb-0"
                onClick={() => navigate(`/bidask/${data?._id}`)}
              >
                Place bid/ask

                <span className="question_mark ms-1"
                  onMouseEnter={() => handleMouseEnter(data?._id)}
                  onMouseLeave={handleMouseLeave}
                //  onClick={() => handleClick(data._id)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="app-best-deal"
                    width="16"
                    height="16"
                    fillRule="currentColor"
                    className="bi bi-question-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
                  </svg>
                  {(tooltipVisible.popUp && tooltipVisible.id === data._id)  && (
                    <div className="custom-tooltip-new-tool">
                      <p className="tool-tip-content text-white">Let the market know at what price you are committed to make a deal.<br />
                        <br />A Bid is the price at which you are willing to buy an item.<br />
                        <br />An Ask is the price you are willing to sell an item.<br />
                        <br /> Your Bid/Ask will stay active until someone accept , expires or you cancel it.<br />
                        <br />If Someone accept your Bid/Ask there is no backing out! <br />
                        <br />For more information please see our <a href="/BuyingandSellingGuide" className="text-primary text-decoration-underline">Buying and Selling Guide</a>
                      </p>
                    </div>
                  )}
                </span>

              </button>
            </>)}
            // price={data.new_retail_website_price }
            model={data.model}
            data={data}
            condition={data.type}
            onCheckboxChange={handleCheckboxChange}
          />
        ))}
      </div>
    </div>
    </>
  );
}
