import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import Header from "./header";
import  Footer from "./footer";
const Cookiepolicy = () => {
  const goBack = () => {
    window.history.back(); // Navigate back in the browser's history
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);
  return (
    <div>
      <Header />
      
      <div
      name="termly-embed"
      data-id="09b9427c-a71f-454b-a953-06d98dfb4548"
      data-type="iframe"
    ></div>
    



    
      <Footer />
    </div>
  );
};

export default Cookiepolicy;
