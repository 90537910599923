import React, { useEffect, useState, useCallback, useRef } from "react";
import { Form, Button } from "react-bootstrap";
import ExportApi from "../../src/api/ExportApi";
import { toast } from "react-toastify";
import { useForm } from 'react-hook-form';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function GlobalDescription() {
    const { register, handleSubmit, setValue, control, reset, formState: { errors }, } = useForm();

    const [count, setCount] = useState();
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [description, setDescription] = useState(null);

    const fetchData = async () => {
        try {
            const res = await ExportApi.getProductDescriptions();
            console.log(res?.data?.descriptions[0], "respp>>>datra>>>>>>>>>123");

            const data = await res?.data?.descriptions[0]
            setDescription(data);
        } catch (error) {
            console.error('Error fetching global description data:', error);
        }
    };
    const onSubmit = (data) => {
        setButtonDisabled(true);
        setCount(0)
        // console.log(data, "data>>>>>>>>>>>>>>>>123");
        // const descriptionWithoutTags = description ? description.replace(/<\/?p>/g, '') : '';
        // data.description = descriptionWithoutTags;
        // console.log(data.description,"data.description>>>>>>>123");
        ExportApi.updateGlobaldescription(data)
            .then((resp) => {
                const data = resp?.data?.message;
                if (data) {
                    toast.success(data)
                    reset();
                    setCount(1)
                    setButtonDisabled(false);
                    fetchData();
                } else {
                    toast.error(data)
                    setCount(1)
                    setButtonDisabled(false);
                }
            }).catch((err) => console.log(err))
        setCount(1)
    };


    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {count == 0 ?
                <div className="loader-icon" style={{ marginBlock: "80px" }}>
                    <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                </div>
                :
                <Form onSubmit={handleSubmit(onSubmit)} className="py-5">
                    <h2 className="mb-4">Global Description</h2>
                    <Form.Group controlId="name" className="mb-4">
                        <CKEditor
                            editor={ClassicEditor}
                            data={description || ''}
                           
                            onChange={(event, editor) => {
                                const data = editor.getData().replace(/<\/?p[^>]*>/g, ''); 
                                setValue('description', data);
                            }}
                        />
                        {errors.name && <p className="form-error">{errors.name.message}</p>}
                    </Form.Group>
                    <Button variant="primary" type="submit" className="mt-5" disabled={isButtonDisabled}>
                        {isButtonDisabled ? 'Submitting...' : 'Submit'}
                    </Button>
                </Form>
            }

        </>

    );
}
