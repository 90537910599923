// Import React and necessary dependencies
import React, { useEffect,useState } from "react";
import Navbar from "../../src/components/navbar"; // Import the Navbar component
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom for navigation
import Cookies from "js-cookie"; // Import Cookies for handling cookies
import ExportApi from "../../src/api/ExportApi";
import { toast } from "react-toastify";

// Define and export the Header component
export default function Header({clearAllFilters=() =>{}}) {
  const navigate = useNavigate(); // Create a navigate function for routing
  const [selectedData, setSelectedData] = useState(null);

 
  const handleSelectedDataChange = (data) => {
    setSelectedData(data);
  };
  useEffect(() => {
    if (Cookies.get("status")) {
      // If the "status" cookie exists, do nothing (user is authenticated)
    } else {
      // If the "status" cookie doesn't exist, prompt for a password
      let value = prompt("Enter Your Password");
    
      // Check if the entered password is correct
      if (value === "E123QWERFG") {
        // If the password is correct, set the "status" cookie with a 1-day expiration
        Cookies.set("status", "true", { expires: 2, path: "" });
      } else {
        // If the password is incorrect, navigate to the "/error" route
        navigate("/error");
      }
    }
  }, []);  

  useEffect(() => {
    const unsubscribe = new URLSearchParams(location.search);
    const newparamValue = unsubscribe.get("unsubscribe");
    // console.log(newparamValue, "newparamValue>>>>>>>>>>>>>113");
    
    if (newparamValue === 'true') {
      const email = unsubscribe.get("email");
      console.log(email, "unsubscribe123");

      ExportApi.unsubscribedata(email)
        .then((response) => {
          // console.log("Unsubscribe API response:", response);
          toast.success(response.data.message);
        })
        .catch((error) => {
          console.error("Error unsubscribing:", error);
          toast.error("Error in unsubscribing");
        });
    }
  }, []);

  // Render the Header component
  return (
    <div className="custom-header">
      {/* {console.log("header")} */}
      <div className="header-part py-3">
        {/* Render the Navbar component */}
        <Navbar onSelectedDataChange={handleSelectedDataChange} 
        //clearAllFilters={clearAllFilters} 
        />
      </div>
    </div>
  );
}

