import React, { useEffect, useState, useContext } from 'react';
import '../products.css';
import ProductsList from '../productsList/ProductsList';
// import { Tooltip as ReactTooltip } from "react-tooltip";
// import "react-tooltip/dist/react-tooltip.css";

export default function BestDeals() {
  // const htmlString =
  //   "<p>These are the best value, meaning highest performance per dollar</p>";
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipContent = "These are the best value, meaning highest performance per dollar";
  return (
    <div className='popular mt-4'>
      <h2 className='mb-3 text-start ps-md-2 fs-4' style={{position:"relative"}}>Best Deals
        {/* <ReactTooltip
          className="custom-tooltip"
          anchorId="app-best-deal"
          place="bottom"
          content=<div
            dangerouslySetInnerHTML={{ __html: htmlString }}
          />
        /> */}
        <svg onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} id="app-best-deal" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle-fill position-absolute" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"></path></svg>
        {showTooltip && (
  <div  style={{ backgroundColor: "#00000094", color: "white", padding: "8px", zIndex: "9999", borderRadius: "15px", padding: "15px", fontSize:"14px" , position: "absolute", top: "-46px"  }}>{tooltipContent}</div>
)}
      </h2>
      <ProductsList title="Most Popular" apiEndpoint="product/getProductList" productType="bestDeal" />
    </div>
  );
}
