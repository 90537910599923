import React, { useEffect, useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useNavigate } from "react-router-dom";
// import "./List.css";
import ExportApi from "../../api/ExportApi";
import { useParams } from "react-router-dom";

function List() {
  const [singlenews, setSingleNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [clickCount, setClickCount] = useState(0);

  console.log(id, "id>>>>123");
  const fetchNewsList = async () => {
    try {
      setLoading(true);
      const resp = await ExportApi.getnewsbyid(id);
      console.log(resp, "respons>>>>>>>>>>123");
      setLoading(false);
      setSingleNews(resp?.data?.data);
    } catch (error) {
      console.error("Error fetching news list:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchNewsList();
  }, []);
  console.log(singlenews, "singlenews123>");

  let startTime = new Date();
  const handleClick = async (newsItemId) => {
    console.log("hit this up");
    try {
      // navigate(`/news/${newsItemId}`);

      const endTime = new Date();
      const timeDifference = endTime - startTime;
      const averageTime = timeDifference / (clickCount + 1); // Increment clickCount

      const averageTimeInMinutes = averageTime / 60000;
      console.log(averageTimeInMinutes, "averageTimeInMinutes");
      const averageTimeInSeconds = averageTimeInMinutes * 60;
      console.log(averageTimeInSeconds, "averageTimeInSeconds");

      const averageTimeRounded = averageTimeInSeconds.toFixed(2);
      const averageTimes = parseFloat(averageTimeRounded);
      const data = {
        averageTime: averageTimes,
        totalCount: clickCount + 1,
      };

      //  console.log(newsItemId,"newsItemId11");
      //  console.log(data,"data321");

      const response = await ExportApi.totalcount(newsItemId, data);

      if (response.ok) {
        console.log("Data posted successfully");
      } else {
        // Handle API errors
        console.error("Failed to post data:", response.statusText);
      }

      setClickCount((prevCount) => prevCount + 1); // Update clickCount
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getEmbedUrl = (link) => {
    try {
      let videoId = '';
  
      // Check if the URL is a short link (e.g., https://youtu.be/VIDEO_ID)
      if (link.includes('youtu.be/')) {
        videoId = link.split('youtu.be/')[1]?.split('?')[0];
      } else {
        // Handle standard YouTube links (e.g., https://www.youtube.com/watch?v=VIDEO_ID)
        videoId = link.split('v=')[1]?.split('&')[0];
      }
  
      // Return the embed URL if videoId is valid
      return videoId ? `https://www.youtube.com/embed/${videoId}` : '';
    } catch (error) {
      console.error('Error extracting video ID:', error);
      return '';
    }
  };
  
  const hasVideos = Array.isArray(singlenews?.embeddedVideoLinks) && singlenews.embeddedVideoLinks.length > 0;
  return (
    <>
      <Header />

      {/* <div className="loader-icon" style={{ marginBlock: "80px" }}>
                    <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                </div> */}

      {loading ? (
        <div className="loader-icon" style={{ marginBlock: "80px" }}>
          <i className="fa-solid fa-spinner fa-spin-pulse"></i>
        </div>
      ) : (
        <div className="pb-5 pt-2" style={{ background: "#f9fafb" }}>
          <Container>
            <Row className="d-flex justify-content-center flex-wrap  p-3 bg-white grid-list mb-5">
              <Col xs={12} md={12} className="ps-0">
                <div
                  className="news-grid"
                  onClick={() => handleClick(singlenews?._id)}
                >
                  <div className="newsimg">
                    <img
                      style={{ width: "auto" }}
                      src={singlenews?.image}
                      alt={singlenews?.heading}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} md={12} className="pe-0">
                <div
                  className="news-grid custom-news-grid my-3 me-5"
                  onClick={() => handleClick(singlenews?._id)}
                >
                  <div className="text-center">
                    <p className="New-categoryy">
                      {singlenews?.categories[0]?.name}
                    </p>
                    <h3 className="mb-3 text-dark fw-bold fs-4 text centered">
                      {singlenews?.heading}
                    </h3>
                    
                  </div>
                  <p
                    className="fs-6 fw-normal"
                    dangerouslySetInnerHTML={{
                      __html: singlenews?.description,
                    }}
                    //  style={{
                    //     maxHeight: '400px',
                    //     overflowY: 'auto',
                    //     overflowX: 'hidden',
                    //     border: '1px solid #ddd',
                    //     padding: '15px',
                    //     backgroundColor: '#f9f9f9',
                    //   }}
                  />
                </div>
                <div> 
              {hasVideos ? (
        singlenews.embeddedVideoLinks.map((link, index) => {
          const embedUrl = getEmbedUrl(link);

          // If the embed URL is invalid or empty, skip rendering this iframe
          if (!embedUrl) return null;

          return (
            <div key={index}>
              <p>Video {index + 1}</p>
              <iframe
                width="100%"
                height="675"
                src={embedUrl}
                title={`Video ${index + 1}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          );
        })
      ) : (
        <p>No videos available</p> // Message when there are no videos
      )}
      </div>
              </Col>
              <Col xs={12} md={12} className="pe-0"> 
              
              </Col>
            </Row>
          </Container>
        </div>
      )}

      <Footer />
    </>
  );
}

export default List;
