import React, { useEffect, useState, useRef, useCallback } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../images/logo.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Link, useNavigate, useLocation, redirect } from "react-router-dom";
import { Modal, ListGroup } from "react-bootstrap";
import Login from "./Login";
import Header from "./header";
import OutsideClickHandler from "react-outside-click-handler";
import { ToastContainer } from "react-toastify";
import TawkTo from "tawkto-react";
import ExportApi from "../api/ExportApi";
import debounce from "lodash.debounce";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function navbar() {
  //{clearAllFilters=()=>{}}
  //function navbar({ searchValue, setSearchValue }) {
  // console.log(searchValue, "searchValue>>navbar");
  const navigate = useNavigate();

  const location = useLocation();

  const [closeIcon, setCloseIcon] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [show, setShow] = useState(false);
  const [DropList, setDropList] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("tokenuser"));
  const [token1, setToken1] = useState(localStorage.getItem("admin"));
  const [userId, setUserId] = useState();
  const [key, setkey] = useState(false);
  const [loaderkey, setloaderkey] = useState(false);
  const divRef = useRef(null);
  let imageUrl = process.env.REACT_APP_IMAGEBASEURL;
  const [Data, setData] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [brandCounts, setBrandCounts] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [modelCounts, setModelCounts] = useState({});
  const [seriesCounts, setSeriesCounts] = useState({});
  const [chipsetCounts, setChipsetCounts] = useState({});
  // const [selectedData, setSelectedData] = useState({});
  const [seriesn, setSeriesn] = useState([]);
  const [modeln, setModeln] = useState([]);
  const [chipsetn, setChipsetn] = useState([]);
  const [brandsn, setBrandsn] = useState([]);
  const [newdata, setNewdata] = useState();
  const [searchdata, setSearchdata] = useState(false);

  const [searchloader, setSearchloader] = useState(false);
  const [blankdata, setBlankdata] = useState();
  const [allproducts, setAllproducts] = useState();
  //console.log(searchValue, "searchValue123");

  // console.log(blankdata, "blankdata12");
  // useEffect(() => {
  //   const Getproductdata = () => {
  //     ExportApi.Alllatestproducts()
  //       .then((resp) => {
  //         const data = resp?.data?.data;
  //         setAllproducts(data)
  //         console.log(data,"GetAllProducts");
           
  //       })
  //       .catch((err) => console.log(err));
  //   };

  //   Getproductdata();
  // }, []);


  useEffect(() => {
    const contactus = new URLSearchParams(location.search);
    const paramValue = contactus.get("contactus");
    if (paramValue) {
      setShowChat(true);
    }
  }, [location.search]);

  useEffect(() => {
    // setUserId(JSON.parse(localStorage.getItem("tokenuser")).id)
    window.addEventListener("Login", () => {
      // Set the user token from localStorage
      setToken(localStorage.getItem("tokenuser"));
      setToken1(localStorage.getItem("admin"));
      setUserId(JSON.parse(localStorage.getItem("tokenuser")).id);
      // Hide a component or perform other actions
      setShow(false);
    });

    // Listen for "Loginout" event
    window.addEventListener("Loginout", () => {
      // Set a state variable to control a dropdown list
      setDropList(false);
      // Clear localStorage data
      localStorage.clear();
      // Clear tokens and navigate to the root path
      setToken();
      setToken1();
      setUserId();
      navigate("/");
    });
  }, []);

  // Function to handle cancel icon click
  const handleCancelIcon = () => {
    // Clear search-related state variables
    handleSearchClear();
    setSearchValue("");
    setCloseIcon(false);
  };

  // Function to handle closing a component or dialog
  const handleClose = () => setShow(false);

  // Function to handle showing a component or dialog
  // const handleShow = () => {

  //   if (!localStorage.getItem("tokenuser") && !localStorage.getItem("admin")) {

  //     setShow(true);
  //   } else {

  //     setShow(false);
  //     setToken(localStorage.getItem("tokenuser"));
  //     setUserId(JSON.parse(localStorage.getItem("tokenuser")).id);
  //     setDropList(true);
  //   }
  // };
  const handleShow = () => {
    const tokenUser = localStorage.getItem("tokenuser");
    const adminUser = localStorage.getItem("admin");

    // Check if tokenuser exists
    if (tokenUser) {
      // Set token and user ID from tokenuser
      setToken(tokenUser);
      const userToken = JSON.parse(tokenUser);

      //console.log("Local storage token data: ", tokenUser)
      setUserId(userToken.id);
      setDropList(true); // Show dropdown list
      setShow(false); // Hide the component
    } else if (adminUser) {
      // Set token and user ID from admin
      setToken(adminUser);
      setUserId(JSON.parse(adminUser).id);
      setDropList(true); // Show dropdown list
      setShow(false); // Hide the component
    } else {
      // No user token found, show the component
      setShow(true);
    }
  };


 
  const handleSearch = async (value) => {
      setSearchloader(true);
      setSearchValue(value);
      setSearchloader(true);
      if (value?.length > 0) {
        setCloseIcon(true);
        setShowPopUp(true);

      try {
        setSearchdata(true);
          const resp = await ExportApi.GetAllProductSerch(value);
          console.log(resp?.data?.Data, "GetAllProductSerch");
          if (resp?.data?.Data) {
            console.log(resp, "handleSearch");
            setBlankdata(resp?.data?.Status)
            setData(resp?.data?.Data);
           
            setSearchValue(value);
            setSearchloader(false);
          } 
        
      } catch (error) {
        console.error("Error fetching data:", error);
        setSearchloader(false);
        setCloseIcon(false);
        setShowPopUp(false);
      } 
      // finally {
      //   setSearchdata(false);
      //   setShowPopUp(false);
      //   setCloseIcon(false);
      //   setSearchloader(false);
      // }
    } else {
 
      setCloseIcon(false);
      setShowPopUp(false);
      
     
    }
  };


  // const handleSearch = (value) => {
  //   setSearchloader(true);
  //   setSearchValue(value);
  //   if (value?.length > 0) {
  //     setCloseIcon(true);
  //     setShowPopUp(true);
  
  //     // Filter the allproducts array based on search value
  //     const filteredData = allproducts.filter((product) => {
  //       // Ensure each property is defined and is a string before calling toLowerCase()
  //       const name = product.name ? product.name.toLowerCase() : '';
  //       const brand = product.brand ? product.brand.toLowerCase() : '';
  //       const series = product.series ? product.series.toLowerCase() : '';
  //       const model = product.model ? product.model.toLowerCase() : '';
  
  //       return (
  //         name.includes(value.toLowerCase()) ||
  //         brand.includes(value.toLowerCase()) ||
  //         series.includes(value.toLowerCase()) ||
  //         model.includes(value.toLowerCase())
  //       );
  //     });
  
  //     if (filteredData.length > 0) {
  //       setData(filteredData);
  //       setBlankdata(false);
  //     } else {
  //       setData([]);
  //       setBlankdata(true);
  //     }
  
  //     setSearchloader(false);
  //   } else {
  //     setCloseIcon(false);
  //     setShowPopUp(false);
  //     setData([]);
  //   }
  // };
  useEffect(() => {
    const timer = setTimeout(() => {
      // console.log("Searching for:", searchValue);

      handleSearch(searchValue);
    }, 2000);

    return () => clearTimeout(timer);
  }, [searchValue]);

  // and triggers the handleSearch function when the searchValue changes.
  // useEffect(() => {
  //   if (searchValue !== undefined) {
  //     handleSearch(searchValue);
  //   }
  // }, [searchValue]);

  // Function to clear search-related data and reset the search input
  const handleSearchClear = (e) => {
    setSearchValue("");
    setData([]);
  };

  useEffect(() => {
    const storedData = localStorage.getItem("tokenuser");
    const userData = storedData ? JSON.parse(storedData) : {};
    // console.log(userData,"userData>>>>>>>135");
    const firstname = userData?.firstname;
    const email = userData?.email;
    // console.log(firstname, email, "userData>>>140");
    const tawkToPropertyId = "6583dce370c9f2407f8220c3";
    const tawkToKey = "1hi5hdmtr";

    var tawkToInstance = new TawkTo(tawkToPropertyId, tawkToKey);

    //  if (showChat) {
    // const tawkToPropertyId = '6583dce370c9f2407f8220c3';
    // const tawkToKey = '1hi5hdmtr';

    // const tawkToInstance = new TawkTo(tawkToPropertyId, tawkToKey);
    // tawkToInstance.toggle();
    // }
    // tawkToInstance.setAttributes({
    //   name: firstname,
    //   email: email
    // });

    //for chat started apis
    tawkToInstance.onChatStarted(() => {
      //console.log("chatstartedd>>>api")
      const storedData = localStorage.getItem("tokenuser");
      const userData = storedData ? JSON.parse(storedData) : {};
      const chatData = {
        timestamp: new Date(),
        userId: userData.id,
      };
      //console.log('dataaaa>>>>', chatData);

      ExportApi.addCreateChat(chatData)
        .then((res) => {
          console.log("Chat created successfully:", res);
        })
        .catch((error) => {
          console.error("Error creating chat:", error);
        });
      console.log("After API call");
    });

    tawkToInstance.onPrechatSubmit(() => {
      const userData = storedData ? JSON.parse(storedData) : {};
      //console.log(userData, "hit this up>>>>>177");
      return {
        name: userData.firstname,
        email: userData.email,
        message: "example1",
      };
    });
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sendbidask = queryParams.get("pagename");
    //console.log('sendbidask:>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', sendbidask);

    if (sendbidask) {
      let token = localStorage.getItem("token");
      const apiUrl =
        "https://api.gpuwiz.com/product/stripeconnectaccountredirect?pagename=" +
        sendbidask;
      // const token `Bearer ${token}`,

      const requestOptions = {
        method: "GET", // Use 'GET', 'POST', 'PUT', 'DELETE', etc. based on your API endpoint
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      fetch(apiUrl, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log("API response bidask:", data);
          console.log(data?.details, "bidask321");
          window.location.replace(data?.details);

          // Handle the API response data
        })
        .catch((error) => {
          console.error("Error bidask:", error);
          // Handle errors
        });
    }
    //console.log('sendbidask:', sendbidask);
    // Do something with sendPage
  }, [location]);

  const calculateBrandCounts = (filteredData) => {
    const counts = {};
    filteredData?.forEach((val) => {
      counts[val.brand] = (counts[val.brand] || 0) + 1;
    });
    setBrandCounts(counts);
  };
  const calculateModelCounts = (filteredData) => {
    const counts = {};
    filteredData?.forEach((val) => {
      counts[val.model] = (counts[val.model] || 0) + 1;
    });
    setModelCounts(counts);
  };
  const calculateSeriesCounts = (filteredData) => {
    const counts = {};
    filteredData?.forEach((val) => {
      counts[val.series] = (counts[val.series] || 0) + 1;
    });
    setSeriesCounts(counts);
  };
  const calculateChipsetCounts = (filteredData) => {
    const counts = {};
    filteredData?.forEach((val) => {
      counts[val.chipset] = (counts[val.chipset] || 0) + 1;
    });
    setChipsetCounts(counts);
  };

  // Filter data based on search input
  useEffect(() => {
    const filtered = Data?.filter((val) =>
      val?.brand?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredData(filtered);
    calculateBrandCounts(filtered);
  }, [searchValue, Data]);

  useEffect(() => {
    const filtered = Data?.filter((val) =>
      val?.model?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredData(filtered);
    calculateModelCounts(filtered);
  }, [searchValue, Data]);

  useEffect(() => {
    const filtered = Data?.filter((val) =>
      val?.series?.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredData(filtered);
    calculateSeriesCounts(filtered);
  }, [searchValue, Data]);

  useEffect(() => {
    const filtered = Data?.filter((val) =>
      val?.chipset.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredData(filtered);
    calculateChipsetCounts(filtered);
  }, [searchValue, Data]);

  useEffect(() => {
    const GetDataFilterList = () => {
      ExportApi.GetAllcategoryList("63ff36fb23ad0386e761641f")
        .then((resp) => {
          const data = resp?.data?.data;
          // console.log(data,"data>GetDataFilterList");
          if (data) {
            setSeriesn(data?.series);
            setModeln(data?.model);
            setChipsetn(data?.chipset);
            setBrandsn(data?.brands);
          }
        })
        .catch((err) => console.log(err));
    };

    GetDataFilterList();
  }, []);

  const lkey = JSON.parse(localStorage.getItem("secondData"));

  useEffect(() => {
    //const loaderkey = JSON.parse(localStorage.getItem('secondData'));
    // setloaderkey(loaderkey);
    if (lkey == true) {
      setloaderkey(lkey);
      //newhandleDataClick(newdata);
    }
  }, [lkey]);

  //console.log(loaderkey,"loaderkey12");

  const handleDataClick = (data) => {
    // clearAllFilters()
    setNewdata(data);
    setShowPopUp(false);
    const newkey = true;
    localStorage.setItem("newkey", newkey);
    navigate(`/categories/63ff36fb23ad0386e761641f`);

    //if (loaderkey == true && newkey == true) {
    console.log("loaderkey&newkey");
    //const clearData = false
    // localStorage.getItem("clearData") === "true" ? "false" : "true";
    //localStorage.setItem("clearData", clearData);
    //const loader = true
    //localStorage.setItem("loaderData", loader);
    // const clearData = "false";
    // localStorage.setItem("clearData", clearData);
    setShowPopUp(false);
    //console.log(data,"data443");

    let index = -1;
    switch (data.type) {
      case "chipset":
        index = chipsetn.findIndex((chip) => chip === data.name);
        break;
      case "brand":
        index = brandsn.findIndex((brand) => brand === data.name);
        break;
      case "series":
        index = seriesn.findIndex((ser) => ser === data.name);
        break;
      case "model":
        index = modeln.findIndex((mod) => mod === data.name);
        break;
      default:
        break;
    }

    data.index = index;
    localStorage.setItem("selectedData", JSON.stringify(data));
    localStorage.removeItem("newkey");
    localStorage.removeItem("secondData");

    navigate(`/categories/63ff36fb23ad0386e761641f`);

    //}
  };

  const clearSeriesCache = () => {
    localStorage.removeItem("series");
    localStorage.removeItem("singleCategory_63ff36fb23ad0386e761641f");
  };

   

  return (
    <div>
      {/* {console.log("User Token Id : ", userId)} */}
      <ToastContainer />
      <Navbar className="px-4" expand="lg">
        <Navbar.Brand href="#home">
          <div className="logo-part">
            <Link to="/">
              <img src={Logo} />
            </Link>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {/* search bar start */}
        <Navbar.Collapse id="basic-navbar-nav">
          <InputGroup className="">
            <InputGroup.Text id="basic-addon1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </InputGroup.Text>
            <Form.Control
              placeholder="Search For GPU"
              aria-label="Username"
              type="text"
              value={searchValue}
              aria-describedby="basic-addon1"
              autoComplete="false"
              // onChange={(e) => {
              //   handleSearch(e.target.value);
              // }}
              onChange={(e) => {
                setSearchValue(e.target.value);
                setSearchloader(true);
                setShowPopUp(true); // Show the pop-up when typing
              }}
              onClick={() => setShowPopUp(true)} // Show the pop-up when clicking inside
            />
            {closeIcon ? (
              <div className="custom_svgCross">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  fill="#000"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                  onClick={() => {
                    handleCancelIcon();
                    setShowPopUp(false);
                    setSearchValue("");
                  }}
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </div>
            ) : (
              ""
            )}
            <OutsideClickHandler
              onOutsideClick={() => {
                setShowPopUp(false);
              }}
            >
              {showPopUp
                ? searchValue && (
                    <div className="">
                      <div
                        className="search-list-custom header-list"
                        ref={divRef}
                      >
                        {searchloader ? (
                          <div>
                            <div
                              className="loader-icon"
                              style={{
                                marginBlock: "80px",
                                marginTop: "20px",
                              }}
                            >
                              <i
                                className="fa-solid fa-spinner fa-spin-pulse"
                                style={{
                                  marginBottom: "100%",
                                  fontSize: "50px",
                                }}
                              ></i>
                            </div>
                          </div>
                        ) : Data?.length > 0 ? (
                          <>
                            <ListGroup
                              as="ul"
                              className={showPopUp ? "" : "hide"}
                            >
                              {Object.keys(brandCounts).map((brand, index) => (
                                <div key={index}>
                                  <button
                                    type="button"
                                    style={{
                                      backgroundColor: "white",
                                      border: "none",
                                      boxShadow: "none",
                                      cursor: "pointer",
                                    }}
                                    title={brand}
                                    onClick={() =>
                                      handleDataClick({
                                        type: "brand",
                                        name: brand,
                                        checked: true,
                                      })
                                    }
                                  >
                                    Total: <b>{brandCounts[brand]}</b> results
                                    found in <b>{brand}</b>
                                  </button>
                                </div>
                              ))}
                              {Object.keys(modelCounts).map((model, index) => (
                                <div key={index}>
                                  <button
                                    type="button"
                                    style={{
                                      backgroundColor: "white",
                                      border: "none",
                                      boxShadow: "none",
                                      cursor: "pointer",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      width: "100%",
                                      textAlign: "left",
                                    }}
                                    title={model}
                                    onClick={() =>
                                      handleDataClick({
                                        type: "model",
                                        name: model,
                                        checked: true,
                                      })
                                    }
                                  >
                                    Total: <b>{modelCounts[model]}</b> results
                                    found in <b>{model}</b>
                                  </button>
                                </div>
                              ))}
                              {Object.keys(seriesCounts).map(
                                (series, index) => (
                                  <div key={index}>
                                    <button
                                      type="button"
                                      style={{
                                        backgroundColor: "white",
                                        border: "none",
                                        boxShadow: "none",
                                        cursor: "pointer",
                                      }}
                                      title={series}
                                      onClick={() =>
                                        handleDataClick({
                                          type: "series",
                                          name: series,
                                          checked: true,
                                        })
                                      }
                                    >
                                      Total: <b>{seriesCounts[series]}</b>{" "}
                                      results found in <b>{series}</b>
                                    </button>
                                  </div>
                                )
                              )}
                              {Object.keys(chipsetCounts).map(
                                (chipset, index) => (
                                  <div key={index}>
                                    <button
                                      type="button"
                                      style={{
                                        backgroundColor: "white",
                                        border: "none",
                                        boxShadow: "none",
                                        cursor: "pointer",
                                      }}
                                      title={chipset}
                                      onClick={() =>
                                        handleDataClick({
                                          type: "chipset",
                                          name: chipset,
                                          checked: true,
                                        })
                                      }
                                    >
                                      Total: <b>{chipsetCounts[chipset]}</b>{" "}
                                      results found in <b>{chipset}</b>
                                    </button>
                                  </div>
                                )
                              )}
                              {Data?.map((val, i) => (
                                <ListGroup.Item
                                  as="li"
                                  key={i}
                                  className=""
                                  onClick={() => {
                                    navigate(`/product/${val?._id}`);
                                    setShowPopUp(false);
                                    setSearchValue("");
                                    setData();
                                  }}
                                >
                                  <div className="d-flex align-items-center gap-5">
                                    <LazyLoadImage
                                      src={val.image[0]}
                                      alt={val.model}
                                    />
                                    <div className="custom-list">
                                      <p className="mb-0">{val.brand}</p>
                                      <h3>{val.series}</h3>
                                      <h3>{val.model}</h3>
                                      <h5 style={{ color: "#0000FF" }}>
                                        Condition:{" "}
                                        {val.type == 1
                                          ? "New"
                                          : val.type == 2
                                          ? "2nd Hand"
                                          : "Used"}
                                      </h5>
                                    </div>
                                  </div>
                                </ListGroup.Item>
                              ))}
                            </ListGroup>
                          </>
                        ) : (
                          <div>
                             {blankdata == false ? (
                              <p className="text-center fs-2">
                                <b>No Data Found</b>
                              </p>
                              ) : (
                              ""
                            )} 
                            {/* {!searchdata && Data?.length == 0 && (
                              <p className="text-center fs-2">
                                <b>No Data Found</b>
                              </p>
                            )} */}
                          </div>
                        )}
                      </div>
                    </div>
                  )
                : ""}
            </OutsideClickHandler>
          </InputGroup>
          <div className="header_right_content">
            <Link
              to="/categories/63ff36fb23ad0386e761641f"
              className="text-dark mx-2 fs-5 fw-500"
            >
              Browse
            </Link>
            <Link to="/userBuySell" className="text-dark mx-2 fs-5 fw-500">
              Buy/Sell
            </Link>
            <Link to="/about" className="text-dark mx-2 fs-5 fw-500">
              About
            </Link>
            <Link to="/list" className="text-dark mx-2 fs-5 fw-500">
              News
            </Link>
            <Link to="/help" className="text-dark mx-2 fs-5 fw-500">
              Help
            </Link>
            <button className="text-dark mx-2 fs-5 fw-500  custom-header">
              {" "}
              <div className="d-flex align-items-center gap-2 lg:ms-3 ">
                {/* <Button className="bg-transparent border-0 p-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    className="bi bi-bell"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                  </svg>
                </Button> */}
                {token || token1 ? (
                  <>
                    <Button
                      className="bg-transparent border-0 p-0"
                      onClick={() => setDropList(!DropList)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        className="bi bi-person-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                        <path
                          filerule="evenodd"
                          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                        />
                      </svg>
                    </Button>
                  </>
                ) : (
                  <Button
                    className="bg-transparent border-0 p-0"
                    onClick={() => handleShow()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      className="bi bi-person-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                      <path
                        filerule="evenodd"
                        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                      />
                    </svg>
                  </Button>
                )}
              </div>
              <OutsideClickHandler
                onOutsideClick={() => {
                  setDropList(false);
                }}
              >
                {DropList ? (
                  <div className="dropdown">
                    <div className="inner_drop">
                      {/* <Link to={`/profile/${userId}`} */}
                      {token || userId ? (
                        <Link to={`/profile`}>Profile</Link>
                      ) : (
                        <Link to="#">Profile</Link>
                      )}
                      {token ? (
                        <Link to="/buySellManagment/selling">Dashboard</Link>
                      ) : null}
                      {token1 ? <Link to="/productlist">Dashboard</Link> : null}
                      {token || token1 ? (
                        <p
                          onClick={() => {
                            clearSeriesCache();
                            navigate("/");
                            window.dispatchEvent(new Event("Loginout"));
                          }}
                          // onClick={handleLogout}
                        >
                          Log Out
                        </p>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </OutsideClickHandler>
            </button>
          </div>
        </Navbar.Collapse>
        {/* search bar closed */}
      </Navbar>
      {/* Login PopUp Start */}
      <Modal show={show} onHide={handleClose} backdrop={true} keyboard={false}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="scrollable-modal-body">
          <Login navigate={navigate} modal={handleClose} />
        </Modal.Body>
      </Modal>
      {/* Login Popup End */}
      <div className={showPopUp ? "addOverlay" : ""}></div>
    </div>
  );
}
