import React, { useState, useEffect } from 'react';
import Banner from './banner/Banner';
import Products from './products/Product';
// import Recommend from './recommend/Recommend';
// import Discover from './discover/Discover';
import Favourites from './products/favourite/Favourites';
import Header from '../../components/header';
import Footer from '../../components/footer';

function Home() {
  // { searchValue, setSearchValue }
  // console.log(searchValue, "searchValue>Home");

  const [isLoading, setIsLoading] = useState(true);
  // const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, []);
  return (
    <div>
      <Header/>
      {/* <Header searchValue={searchValue} setSearchValue={setSearchValue} /> */}
      {isLoading ? (
        <div className="loader-icon" style={{ marginBlock: "80px" }}>
          <i
            class="fa-solid fa-spinner fa-spin-pulse"
            style={{ marginBottom: "100%" }}
          ></i>
        </div>
      ) : (
        <>
          <Banner />
          <Products />
          {/* <Recommend /> */}
          {/* <Discover /> */}
          <Favourites />
        </>
      )}
      <Footer />
    </div>
  )
}

export default Home