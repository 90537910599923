import React, { useState, useEffect, useMemo } from "react";
import ExportApi from "../api/ExportApi";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";


const Updatebulkprice = () => {
  const [seriesData, setSeriesData] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterloader, setFilterLoader] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const fetchallSeries = async () => {
    setFilterLoader(true);
    try {
      const response = await ExportApi.getallseriesdata();
      console.log(response, "fetchallSeries123");
      setFilterLoader(false);
      setSeriesData(response.data.Data);
    } catch (error) {
      setFilterLoader(false);
      console.error("Error fetching admin series:", error);
    }
  };

  useEffect(() => {
    fetchallSeries();
  }, []);

  const handleEdit = (index, field, value) => {
    console.log(index,field,value,"alldata");
    const updatedSeriesData = [...sortedData];
    updatedSeriesData[index][field] = value;
    console.log(updatedSeriesData[index][field],"newdata1")
    console.log(seriesData,"seriesData123");
    // setSeriesData(updatedSeriesData);
  
    const editedItem = updatedSeriesData[index];
    const existingIndex = editedData.findIndex(
      (item) => item.series === editedItem.series
    );

    if (existingIndex !== -1) {
      const updatedEditedData = [...editedData];
      updatedEditedData[existingIndex] = editedItem;
      setEditedData(updatedEditedData);
    } else {
      setEditedData((prevData) => [...prevData, editedItem]);
    }
  };


  const updateBulk = async () => {
    setLoading(true);
    try {
      const response = await ExportApi.Updatebulkproduct(editedData);
      console.log(response, "rewspppppp");
      if (response?.data?.message == "Product data updated successfully") {
        toast.success("Data updated successfully!");
        fetchallSeries();
      }
      if (response?.data?.status == false) {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      console.error(error, "bulkupdate");
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (column) => {
    let direction = "ascending";
    console.log(sortConfig.key, "sortConfig.ke111");
    if (sortConfig.key === column && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: column, direction });
  };
  const sortedData = useMemo(() => {
    if (sortConfig.key !== null) {
      return [...seriesData].sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];
  
        // Normalize values
        aValue = aValue === 0 || aValue === null || aValue === undefined ? "" : aValue;
        bValue = bValue === 0 || bValue === null || bValue === undefined ? "" : bValue;
  
        //console.log(`Comparing ${aValue} and ${bValue}`); 
  
        // Handle numeric sorting if both are numbers
        if (!isNaN(aValue) && !isNaN(bValue)) {
          aValue = Number(aValue);
          bValue = Number(bValue);
          return sortConfig.direction === "ascending"
            ? aValue - bValue
            : bValue - aValue;
        }
  
        // Handle string sorting with embedded numbers
        if (typeof aValue === "string" && typeof bValue === "string") {
          // Extract numeric part and compare
          const extractNumber = (str) => {
            const match = str.match(/\d+/);
            return match ? parseInt(match[0], 10) : 0;
          };
  
          const extractAlpha = (str) => {
            return str.replace(/\d+/g, '').trim();
          };
  
          const aNumber = extractNumber(aValue);
          const bNumber = extractNumber(bValue);
  
          const aAlpha = extractAlpha(aValue);
          const bAlpha = extractAlpha(bValue);
  
          if (aAlpha === bAlpha) {
            return sortConfig.direction === "ascending"
              ? aNumber - bNumber
              : bNumber - aNumber;
          }
  
          return sortConfig.direction === "ascending"
            ? aAlpha.localeCompare(bAlpha)
            : bAlpha.localeCompare(aAlpha);
        }
  
        // Default case for non-comparable values
        return 0;
      });
    }
    return seriesData;
  }, [seriesData, sortConfig]);
  
  
  
  

  return (
    <div>
      <h2>Update Bulk Price</h2>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{ fontWeight: "bold" }}
                onClick={() => handleSort("series")}
              >
                GPU Series
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-chevron-expand"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                  />
                </svg>
              </TableCell>

              <TableCell
                style={{ fontWeight: "bold" }}
                onClick={() => handleSort("benchmark_score")}
              >
                Benchmark
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-chevron-expand"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                  />
                </svg>
              </TableCell>

              <TableCell
                style={{ fontWeight: "bold" }}
                onClick={() => handleSort("used_house_ask")}
              >
                House Ask Used
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-chevron-expand"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                  />
                </svg>
              </TableCell>

              <TableCell
                style={{ fontWeight: "bold" }}
                onClick={() => handleSort("used_house_bid")}
              >
                House Bid Used
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-chevron-expand"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                  />
                </svg>
              </TableCell>

              <TableCell
                style={{ fontWeight: "bold" }}
                onClick={() => handleSort("new_second_hand_house_ask")}
              >
                House Ask New
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-chevron-expand"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                  />
                </svg>
              </TableCell>

              <TableCell
                style={{ fontWeight: "bold" }}
                onClick={() => handleSort("new_second_hand_house_bid")}
              >
                House Bid New
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-chevron-expand"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                  />
                </svg>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {filterloader ? (
              <div>
                <h5>
                  <b>Loading...</b>
                </h5>
              </div>
            ) : (
              sortedData.map((series, index) => (
                <TableRow key={index}>
                  <TableCell>{series.series || ""}</TableCell>
                  <TableCell>
                    <input
                      type="number"
                      value={series.benchmark_score || ""}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) {
                          handleEdit(
                            index,
                            "benchmark_score",
                            value === "" ? null : Number(value)
                          );
                        }
                      }}
                      className="bulktablerow"
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      type="number"
                      value={series.used_house_ask || ""}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Ensure the value is a valid number or empty
                        if (/^\d*$/.test(value)) {
                          handleEdit(
                            index,
                            "used_house_ask",
                            value === "" ? null : Number(value)
                          );
                        }
                      }}
                      className="bulktablerow"
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      type="number"
                      value={series.used_house_bid || ""}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) {
                          handleEdit(
                            index,
                            "used_house_bid",
                            value === "" ? null : Number(value)
                          );
                        }
                      }}
                      className="bulktablerow"
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      type="number"
                      value={series.new_second_hand_house_ask || ""}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Ensure the value is a valid number or empty
                        if (/^\d*$/.test(value)) {
                          handleEdit(
                            index,
                            "new_second_hand_house_ask",
                            value === "" ? null : Number(value)
                          );
                        }
                      }}
                      className="bulktablerow"
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      type="number"
                      value={series.new_second_hand_house_bid || ""}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Ensure the value is a valid number or empty
                        if (/^\d*$/.test(value)) {
                          handleEdit(
                            index,
                            "new_second_hand_house_bid",
                            value === "" ? null : Number(value)
                          );
                        }
                      }}
                      className="bulktablerow"
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

        {filterloader ? (
          ""
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <Button
              variant="contained"
              color="primary"
              onClick={updateBulk}
              disabled={loading}
            >
              {loading ? "Updating..." : "Update"}
            </Button>
            &nbsp; &nbsp;
          </div>
        )}
      </TableContainer>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
};

export default Updatebulkprice;
