import React, { useRef, useEffect, useState } from "react";
import {
  Form,
  Button,
  Modal,
  Table,
  FormLabel,
  FormControl,
  FormCheck,
  FormGroup,
  Row,
} from "react-bootstrap";
import { toast } from "react-toastify";
import ExportApi from "../../api/ExportApi";
import { BiPlusCircle } from "react-icons/bi";
import { BiMinusCircle } from "react-icons/bi";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Pagination from "@mui/material/Pagination";

export default function Adminnews() {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [newsIdToDelete, setNewsIdToDelete] = useState(null);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [newsList, setNewsList] = useState([]);
  const imageUrl = process.env.REACT_APP_IMAGEBASEURL;
  const [editNews, setEditNews] = useState(null);
  const [inputList, setInputList] = useState([""]);
  const [formData, setFormData] = useState({
    heading: "",
    description: "",
    image: "",
    unfeatured: false,
    featured: false,
    productVideos: [],
    categories: [],
  });
  const [showModal, setShowModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  // const [showimage, setShowimage] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentage, setCurrentPage] = useState();
   
  const [page, setPage] = useState(1);
  const [totalpages, setTotalPages] = useState(1);
  const editorRef = useRef();
  const navigate = useNavigate();

 
 
 
  const handleShowDeleteModal = (newsId) => {
    console.log(newsId, "newsId>>>>>>>>>>>handleShowDeleteModal11");
    setNewsIdToDelete(newsId);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async (newsId) => {

    try {
      await ExportApi.deleteNewsbyid(newsId);
      setShowDeleteModal(false);
      fetchNewsList();
      toast.success("News deleted successfully.");
    } catch (error) {
      toast.error("Error deleting news");
    }
  };

  const handleDeleteCancel = () => {
    setNewsIdToDelete(null);
    setShowDeleteModal(false);
  };

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  useEffect(() => {
    if (editorRef.current && editorLoaded) {
      const editor = editorRef.current.editor;

      const handleKeyDown = (event) => {
        if (event.key === "Backspace") {
          const selection = editor.model.document.selection;
          const selectedElement = selection.getSelectedElement();
          if (
            !selectedElement &&
            selection.getFirstPosition().inParagraph.isAtStart
          ) {
            const range = editor.model.document.selection.getFirstRange();
            editor.model.change((writer) => {
              writer.remove(range);
            });
          }
        }
      };

      editor.editing.view.document.on("keydown", handleKeyDown);

      return () => {
        editor.editing.view.document.off("keydown", handleKeyDown);
      };
    }
  }, [editorLoaded]);

  const fetchNewsList = async (page) => {
    console.log(page,"fetchNewsList");
    setLoading(true);
    try {
      const response = await ExportApi.getAllNewsByPage(page);
      console.log(response, "getAllnews");
      setLoading(false);
      setNewsList(response?.data?.data);
      setPage(response?.data?.currentPage);
      setTotalPages(response?.data?.totalPages);
      setCurrentPage(response?.data?.currentPage);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching news list:", error);
    }
  };

  const handlePageClick = (event, value) => {
    console.log(value,"handlePageClick");
    // setPage(value);
    fetchNewsList(value);
  };
  const handleEdit = (newsId, news) => {
    const newsToEdit = newsList.find((news) => news._id === newsId);

    console.log(news, "news.embeddedVideoLinks>>>>>>>>>handleEdit");
    setEditNews(newsToEdit);
    const { categories } = news;
    setFormData({
      heading: newsToEdit.heading,
      description: newsToEdit.description,
      image: newsToEdit.image,
      unfeatured: newsToEdit.unfeatured,
      featured: newsToEdit.featured,
      productVideos: news.embeddedVideoLinks,
      categories: categories.map((category) => ({
        name: category.name,
        _id: category._id,
        checked: true,
        subcategories: category.subcategories.map((subcategory) => ({
          name: subcategory.name,
          _id: subcategory._id,
          checked: true,
        })),
      })),
    });
    setInputList(news.embeddedVideoLinks);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setShowModal(false);
    setEditNews(null);
    setFormData({
      heading: "",
      description: "",
      image: "",
      unfeatured: false,
      featured: false,
      productVideos: [],
      categories: [],
    });
  };

  const handleChange = (e) => {
    const { name, type, files } = e.target;

    if (type === "file") {
      const imageFile = files[0];

      // Validate file type
      const allowedTypes = ["image/jpeg", "image/png", "image/webp"];
      if (!allowedTypes.includes(imageFile.type)) {
        alert("Only jpeg, png, and webp files are supported.");
        return;
      }

      // Validate file size
      const maxSize = 1 * 1024 * 1024; // 1 MB
      if (imageFile.size > maxSize) {
        alert("File size exceeds 1 MB limit.");
        return;
      }

      setSelectedImage(imageFile);
      setFormData((prevState) => ({
        ...prevState,
        image: imageFile,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: e.target.value,
      }));
    }
  };

  console.log(selectedImage, "selectedImage321");
  const handleChangeFeatured = (e) => {
    const { checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      featured: checked,
    }));
  };

  const handleChangeUnfeatured = (e) => {
    const { checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      unfeatured: checked,
    }));
  };

  const addCategory = () => {
    const newCategory = { name: "", checked: false, subcategories: [] };
    setCategories([...categories, newCategory]);
  };

  const removeCategory = (categoryIndex) => {
    const updatedCategories = [...categories];
    updatedCategories.splice(categoryIndex, 1);
    setCategories(updatedCategories);
  };

  const handleCategoryChange = (categoryIndex) => {
    const updatedCategories = [...categories];
    updatedCategories[categoryIndex].checked =
      !updatedCategories[categoryIndex].checked;
    setCategories(updatedCategories);
  };

  const addSubcategory = (categoryIndex) => {
    const updatedCategories = [...categories];
    updatedCategories[categoryIndex].subcategories.push({
      name: "",
      checked: false,
    });
    setCategories(updatedCategories);
  };

  const removeSubcategory = (categoryIndex, subcategoryIndex) => {
    const updatedCategories = [...categories];
    updatedCategories[categoryIndex].subcategories.splice(subcategoryIndex, 1);
    setCategories(updatedCategories);
  };

  const handleSubcategoryChange = (categoryIndex, subcategoryIndex) => {
    const updatedCategories = [...categories];
    updatedCategories[categoryIndex].subcategories[subcategoryIndex].checked =
      !updatedCategories[categoryIndex].subcategories[subcategoryIndex].checked;
    setCategories(updatedCategories);
  };
  const handleSaveChanges = async () => {
    setIsLoading(true);
    try {
      const newsData = new FormData();
      newsData.append("heading", formData.heading);
      newsData.append("description", formData.description);
      newsData.append("image", formData.image);
      newsData.append("unfeatured", formData.unfeatured);
      newsData.append("featured", formData.featured);
      newsData.append("imageid", editNews?._id);
      formData.productVideos.forEach((video) => {
        console.log(video, "video>>3211");
        newsData.append("embeddedVideoLinks", video);
      });
      // formData.categories.forEach((category, categoryIndex) => {
      //     newsData.append(`categories[${categoryIndex}]`, category.name);
      //     // newsData.append(`categories[${categoryIndex}][checked]`, category.checked);

      //     category.subcategories.forEach((subcategory, subcategoryIndex) => {
      //         newsData.append(`categories[${categoryIndex}][subcategories][${subcategoryIndex}]`, subcategory.name);
      //         // newsData.append(`categories[${categoryIndex}][subcategories][${subcategoryIndex}][checked]`, subcategory.checked);
      //     });
      // });

      const categoriesString = JSON.stringify(formData.categories);
      newsData.append("allCategories", categoriesString);

      const response = await ExportApi.updateNewsbyid(editNews._id, newsData);
      fetchNewsList();
      setShowModal(false);
      setIsLoading(false);
      toast.success(response?.data?.message);
    } catch (error) {
      setIsLoading(false);
      console.error("Error updating news:", error);
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchNewsList();
  }, []);

  const handleChangeProductVideos = (e, index) => {
    const { value } = e.target;
    console.log(value, "value>>handleChangeProductVideos");
    const list = [...inputList];
    list[index] = value;
    setInputList(list);
    setFormData((prevState) => ({
      ...prevState,
      productVideos: list,
    }));
  };

  const handleAddClick = () => {
    setInputList([...inputList, ""]);
  };

  const handleRemoveClick = (index) => {
    if (inputList.length === 1) return;
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    setFormData((prevState) => ({
      ...prevState,
      productVideos: list,
    }));
  };

  return (
    <>
      {loading ? (
        <div className="loader-icon" style={{ marginBlock: "80px" }}>
          <i className="fa-solid fa-spinner fa-spin-pulse"></i>
        </div>
      ) : (
        <div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Image</th>
                <th>Type</th>
                <th>Heading</th>
                <th>Description</th>
                <th>Total Count</th>
                <th>Total Average Time</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {newsList.map((news, index) => (
                <tr key={news._id}>
               <td>{(currentage - 1) * 10 + index + 1}</td>
                  <td onClick={() => navigate(`/news/${news?._id}`)}>
                    <img
                      src={`${news?.image}`}
                      alt="News"
                      height="50px"
                      width="50px"
                    />
                  </td>
                  <td>
                
                    {`${news.unfeatured === true ? "unfeatured" : ""} ${
                      news.featured === true ? "featured" : ""
                    }`}
                  </td>
                  <td
                    style={{
                      maxWidth: "200px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {news.heading}
                  </td>
                  <td
                    style={{
                      maxWidth: "200px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    // dangerouslySetInnerHTML={{ __html: news.description.replace(/<img[^>]*>|<\/?strong[^>]*>|<\/?a[^>]*>/g, '') }}
                  >
                    {news.description.replace(
                      /<img[^>]*>|<\/?(?:strong|i|h2|a|br|p|figure|table|tbody|tr|th)[^>]*>|&(nbsp|#160);|\/+/g,
                      ""
                    )}
                  </td>
                  <td>{news?.totalCount}</td>
                  <td>{news?.averageTime} m/s</td>
                  <td>
                    <Button
                      variant="info"
                      //  onClick={() => handleView(news._id)}
                      onClick={() => navigate(`/news/${news._id}`)}
                    >
                      View
                    </Button>{" "}
                    &nbsp;
                    <Button
                      variant="primary"
                      onClick={() => handleEdit(news._id, news)}
                    >
                      Edit
                    </Button>{" "}
                    &nbsp;
                    <Button
                      variant="danger"
                      onClick={() => handleShowDeleteModal(news._id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
            <div className="d-flex align-items-center justify-content-end">
              <Pagination
                className="order_pagination"
                count={totalpages}
                color="primary"
                hidePrevButton
                hideNextButton
                page={page}
                onChange={handlePageClick}
              />
            </div>
          
           <br></br><br></br>
           <br></br><br></br>
        </div>
      )}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit News</Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollable-modal-body">
          <Form>
            <Form.Group controlId="heading">
              <Form.Label>Heading</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter news heading"
                name="heading"
                value={formData.heading}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="unfeatured" className="mb-3">
              <Form.Label className="fw-bold fs-6">Type</Form.Label>
              <Form.Check
                type="checkbox"
                label="Unfeatured"
                checked={formData.unfeatured}
                onChange={handleChangeUnfeatured}
              />
            </Form.Group>
            <Form.Group controlId="featured" className="mb-3">
              <Form.Check
                type="checkbox"
                label="Featured"
                checked={formData.featured}
                onChange={handleChangeFeatured}
              />
            </Form.Group>

            <Form.Group controlId="categories" className="mb-3">
              <Form.Label className="fw-bold fs-6">Categories</Form.Label>
              {formData.categories.map((category, categoryIndex) => (
                <div key={categoryIndex}>
                  <FormControl
                    type="text"
                    placeholder="Enter category name"
                    value={category.name}
                    onChange={(e) => {
                      const updatedCategories = [...formData.categories];
                      updatedCategories[categoryIndex].name = e.target.value;
                      setFormData((prevState) => ({
                        ...prevState,
                        categories: updatedCategories,
                      }));
                    }}
                  />
                  <FormCheck
                    type="checkbox"
                    id="category"
                    label="Category"
                    checked={category.checked === true}
                    onChange={() => handleCategoryChange(categoryIndex)}
                  />
                  {category.checked &&
                    category.subcategories &&
                    category.subcategories.map(
                      (subcategory, subcategoryIndex) => (
                        <div key={subcategoryIndex}>
                          <FormControl
                            type="text"
                            placeholder="Enter subcategory name"
                            value={subcategory.name}
                            onChange={(e) => {
                              const updatedCategories = [
                                ...formData.categories,
                              ];
                              updatedCategories[categoryIndex].subcategories[
                                subcategoryIndex
                              ].name = e.target.value;
                              setFormData((prevState) => ({
                                ...prevState,
                                categories: updatedCategories,
                              }));
                            }}
                          />
                          <FormCheck
                            type="checkbox"
                            id="subcategory"
                            label="Subcategory"
                            checked={subcategory.checked === true}
                            onChange={() =>
                              handleSubcategoryChange(
                                categoryIndex,
                                subcategoryIndex
                              )
                            }
                          />
                          {/* <button
                                    type="button"
                                    className="btn btn-danger ms-2"
                                    onClick={() => removeSubcategory(categoryIndex, subcategoryIndex)}
                                >
                                    Remove Subcategory
                                </button> */}
                        </div>
                      )
                    )}
                  <br />
                  {/* <Button variant="primary" onClick={() => addSubcategory(categoryIndex)}>
                            Add Subcategory
                        </Button> */}
                  {/* <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => removeCategory(categoryIndex)}
                        >
                            Remove Category
                        </button> */}
                </div>
              ))}
              {/* <Button variant="primary" onClick={addCategory}>
                    Add Category
                </Button> */}
            </Form.Group>

            <Form.Group controlId="image">
              <Form.Label className="fw-bold fs-6">Image</Form.Label>
              <Form.Control
                type="file"
                name="image"
                accept="image/*"
                onChange={handleChange}
              />
              {selectedImage && (
                <img
                  src={URL.createObjectURL(selectedImage)}
                  alt="Selected Image"
                  style={{ marginTop: "10px", width: "300px", height: "300px" }}
                />
              )}
              {editNews && editNews?.image && !selectedImage && (
                <>
                  <div className="custom-div-sec">
                    <img
                      // src={`${imageUrl}${editNews.image}`}
                      src={editNews?.image}
                      alt="News Image"
                      style={{
                        marginTop: "10px",
                        width: "300px",
                        height: "300px",
                      }}
                    />
                    {/* <div className="icon_div custom-del-ic">
                                <IconButton color="error"
                                
                                 onClick={handleDeleteImage}
                                 >
                                    <DeleteIcon /> 
                                </IconButton>
                            </div> */}
                  </div>
                </>
              )}
            </Form.Group>

            <Form.Group controlId="description">
              <Form.Label className="fw-bold fs-6">Description</Form.Label>

              {/* <CKEditor
                                editor={ClassicEditor}
                                data={formData.description}
                                onChange={(event, editor) => {
                                    const descriptionData = editor.getData();
                                    setFormData(prevState => ({
                                        ...prevState,
                                        description: descriptionData
                                    }));
                                }}
                                config={{
                                    enterMode: 'br',
                                    fillEmptyBlocks: false
                                }}
                            /> */}
              <CKEditor
                editor={ClassicEditor}
                data={formData.description}
                config={{
                  htmlDataProcessor: {
                    allowedContent: true,
                    extraAllowedContent: "style",
                  },
                  fontSize: {
                    options: [],
                    supportAllValues: true,
                  },
                  initialData: ``,
                  enterMode: "br",
                  fillEmptyBlocks: true,

                  toolbar: {
                    items: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "link",
                      "bulletedList",
                      "numberedList",
                      "|",
                      "indent",
                      "outdent",
                      "|",
                      "blockQuote",
                      "insertTable",
                      "|",
                      "undo",
                      "redo",
                      "mediaEmbed"
                    ],
                  },
                  mediaEmbed: {
                    previewsInData: true
                  },
                  ckfinder: {
                    options: {
                      resourceType: "Images",
                    },
                  },
                }}
                onChange={(event, editor) => {
                  const descriptionData = editor.getData();
                  setFormData((prevState) => ({
                    ...prevState,
                    description: descriptionData,
                  }));
                }}
              />
            </Form.Group>
            <Form.Group controlId="productVideos" className="mb-3">
              <Form.Label className="fw-bold fs-6">
                Product Videos Links
              </Form.Label>
              <Row className="g-3">
                {console.log(inputList, "inputList>>>>>>>map")}
                {inputList.map((link, i) => {
                  console.log(link, i, "inputList>>>>link");
                  return (
                    <div
                      className="d-flex mb-3 justify-content-between"
                      key={i}
                    >
                      <FormControl
                        name={`embeddedVideoLink${i + 1}`}
                        placeholder="Embedded Video Link"
                        value={link}
                        autoComplete="off"
                        onChange={(e) => handleChangeProductVideos(e, i)}
                      />
                      {i === inputList.length - 1 && (
                                                <button type="button" className="butt bg-none border-0" onClick={handleAddClick}>
                                                    <BiPlusCircle style={{ fontSize: "30px" }} />
                                                </button>
                                            )}
                                            {i !== 0 && (
                                                <button type="button" className="bg-none border-0 ms-2" onClick={() => handleRemoveClick(i)}>
                                                    <BiMinusCircle style={{ fontSize: "30px" }} />
                                                </button>
                                            )}
                    </div>
                  );
                })}
              </Row>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            {/* Update */}
            {isLoading ? "Updating..." : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDeleteModal} onHide={handleDeleteCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollable-modal-body">
          Are you sure you want to delete this news?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteCancel}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => handleDeleteConfirm(newsIdToDelete)}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
