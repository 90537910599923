import React, { useRef, useEffect, useState } from "react";
import "./About.css";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { useSpring, animated } from '@react-spring/web';
import { AnimateOnChange } from 'react-animation';
import MobileView from "./MobileView";
const Index = () => {
  const containerRef = useRef();
  const [activeBullet, setActiveBullet] = useState("section1");

  // useEffect(() => {
  //   const container = containerRef.current;

  //   let isScrolling = false;

  //   const handleScroll = (event) => {
  //     event.preventDefault();

  //     if (isScrolling) return;

  //     isScrolling = true;

  //     setTimeout(() => {
  //       isScrolling = false;

  //       const deltaY = event.deltaY;
  //       console.log(deltaY,"deltaY");
  //       const currentScrollTop = container.scrollTop;
  //       console.log(currentScrollTop,"currentScrollTop")
  //       const sectionHeight = window.innerHeight;
  //       console.log(sectionHeight,"sectionHeight");

  //       const direction = deltaY > 0 ? 1 : -1;

  //       const nextSectionIndex =
  //         Math.floor((currentScrollTop + sectionHeight / 2) / sectionHeight) +
  //         direction;

  //         console.log(nextSectionIndex,"nextSectionIndex")
  //       const nextSection = container.children[nextSectionIndex];

  //       if (nextSection) {
  //         nextSection.scrollIntoView({ behavior: 'smooth' });
  //         setActiveBullet(nextSection.id);
  //       }
  //     }, 500);
  //   };

  //   container.addEventListener("wheel", handleScroll);

  //   return () => {
  //     container.removeEventListener("wheel", handleScroll);
  //   };
  // }, []);
  
 

  useEffect(() => {
    const container = containerRef.current;
    let isScrolling = false;
    let timeout;

    const handleScroll = (event) => {
      event.preventDefault();

      if (isScrolling) return;

      isScrolling = true;

      clearTimeout(timeout);
      timeout = setTimeout(() => {
        isScrolling = false;

        const deltaY = event.deltaY;
        const currentScrollTop = container.scrollTop;
        const sectionHeight = window.innerHeight;

        const direction = deltaY > 0 ? 1 : -1;

        const nextSectionIndex =
          Math.floor((currentScrollTop + sectionHeight / 2) / sectionHeight) +
          direction;

        const nextSection = container.children[nextSectionIndex];

        if (nextSection) {
          nextSection.scrollIntoView({ behavior: 'smooth' });
          setActiveBullet(nextSection.id);
        }
      }, 500);
    };

    container.addEventListener("wheel", handleScroll);

    return () => {
      container.removeEventListener("wheel", handleScroll);
    };
  }, [activeBullet]);
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    //console.log(section,"section321");
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      setActiveBullet(sectionId);
    }
  };

  // animated test functionality
  const springs = useSpring({
    from: { x: 0 },
    to: { x: 100 },
  })

  return (
    <>
      <div className="desktop-slider">
        <div className="button-section">
          <div className="btn-box">
            <button
              className={activeBullet === "section1" ? "active" : "inactive"}
              onClick={() => scrollToSection("section1")}
            ></button>
            <div className="custom-bt-tooltip">
              <p >Used GPU Market</p>
            </div>
          </div>
          <div className="btn-box">
            <button
              className={activeBullet === "section2" ? "active" : "inactive"}
              onClick={() => scrollToSection("section2")}
            ></button>
            <div className="custom-bt-tooltip">
              <p>Buying on GPUwiz</p>
            </div>
          </div>
          <div className="btn-box">
            <button
              className={activeBullet === "section3" ? "active" : "inactive"}
              onClick={() => scrollToSection("section3")}
            ></button>
            <div className="custom-bt-tooltip">
              <p>Section 3</p>
            </div>
          </div>
          <div className="btn-box">
            <button
              className={activeBullet === "section4" ? "active" : "inactive"}
              onClick={() => scrollToSection("section4")}
            ></button>
            <div className="custom-bt-tooltip">
              <p>Section 4</p>
            </div>
          </div>
          <div className="btn-box">
            <button
              className={activeBullet === "section5" ? "active" : "inactive"}
              onClick={() => scrollToSection("section5")}
            ></button>
            <div className="custom-bt-tooltip">
              <p>Downside for Buyers?</p>
            </div>
          </div>
          <div className="btn-box">
            <button
              className={activeBullet === "section6" ? "active" : "inactive"}
              onClick={() => scrollToSection("section6")}
            ></button>
            <div className="custom-bt-tooltip">
              <p>Selling on GPUwiz</p>
            </div>
          </div>
          <div className="btn-box">
            <button
              className={activeBullet === "section7" ? "active" : "inactive"}
              onClick={() => scrollToSection("section7")}
            ></button>
            <div className="custom-bt-tooltip">
              <p>Selling on GPUwiz</p>
            </div>
          </div>
          <div className="btn-box">
            <button
              className={activeBullet === "section8" ? "active" : "inactive"}
              onClick={() => scrollToSection("section8")}
            ></button>
            <div className="custom-bt-tooltip">
              <p>Downside for sellers</p>
            </div>
          </div>
        </div>
        <Header />
        <div className="scroll-main" ref={containerRef}>

          <div id="section1" className="secs" data-aos="fade-up">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-6 mx-auto  text-center">
                  <h2 className="fw-bold mb-4 text-white" data-aos="zoom-in">Used GPU Market</h2>
                  <p className="text-white" data-aos="zoom-in">
                    Buying a used GPU can be a nightmare.
                  </p>
                  <p className="text-white" data-aos="zoom-in">
                    There are hundreds of options to chose from and they all vary in performance.
                  </p>
                  <p className="text-white">Even if you know exactly what model you want, when you type it into a search you still get hundreds of options.</p>
                  <p className="text-white">Finding the best price, assessing the condition, and finding a seller you can trust can be a time consuming and difficult process.</p>
                  <p className="text-white">It should not be like this!</p>

                </div>
                <div className="col-lg-8 col-md-12"></div>
              </div>
            </div>
          </div>
          <div id="section2" className="secs" data-aos="fade-up">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-12 text-center me-5 custom-text">
                  <h2 className="fw-bold mb-4 text-center" data-aos="zoom-in">Buying on GPUwiz</h2>
                  <p className="fw-bold  text-center" data-aos="zoom-in">
                    We make buying used graphics cards easy!
                  </p>
                  <p data-aos="zoom-in" className=" text-center">
                    Buying a used GPU should be as simple as selecting the model
                    you want and clicking "Buy Now", knowing you are getting a
                    product that is in excellent condition at a fair market price.
                  </p>
                  <p className="fw-bold  text-center" data-aos="zoom-in">This is what we make possible!</p>
                </div>
                <div className="col-lg-8 col-md-12"></div>
              </div>
            </div>
          </div>
          <div id="section3" className="secs" data-aos="fade-up">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 mx-auto text-center">
                  <h2 className="fw-bold text-white mb-4">How?</h2>
                  <p className="text-white">
                    We make sure every used GPU meets our quality standards
                    through physical inspection and testing!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="section4" className="secs" data-aos="fade-up">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 mx-auto text-center">
                  <h2 className="fw-bold text-white mb-4">How?</h2>
                  <p className="text-white">
                    Similar to the stock market, we let users set the price so you
                    are buying the lowest cost option.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="section5" className="secs" data-aos="fade-up">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-12 text-center">
                  <h2 className="fw-bold mb-4">Downside for Buyers?</h2>
                  <p className="">
                    Shipping can take longer as we need to receive and test every
                    GPU.
                  </p>
                  <p className="">
                    All purchases are final and there are no returns offered as
                    this a live marketplace for used items.
                  </p>
                  <p className="">
                    Similar to the stock market, when you buy a stock you cannot
                    return it, but you are free to sell it at anytime.
                  </p>
                </div>
                <div className="col-lg-7 col-md-12"></div>
              </div>
            </div>
          </div>
          <div id="section6" className="secs" data-aos="fade-up">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 mx-auto text-center">
                  <h2 className="fw-bold mb-4">Selling on GPUwiz</h2>
                  <p className="fw-bold">No pictures and descriptions!</p>
                  <p>
                    Taking pictures and creating a listing with descriptions is
                    time consuming and unnecessary.
                  </p>
                  <p>
                    We do not want you wasting your time, instead, its more
                    important to verify the graphics cards are in good condition
                    by physical inspection and benchmarking.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="section7" className="secs" data-aos="fade-up">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-12 mx-auto text-center">
                  <h2 className="fw-bold mb-4 text-white">
                    Selling on GPUwiz
                  </h2>
                  <p className="text-white ">
                    Sell instantly and save time!
                  </p>
                  <p className="text-white ">
                    Pricing your graphics card can be time consuming. You have to research previous sales and current listings to price your GPU. You also have to wait for your item to sell and communicate with buyers.
                  </p>
                  <p className="text-white ">
                    Not anymore, we tell you exactly what the live market price is so you can simply chose if you want to be the lowest priced on the market or you can sell instantly to the highest bidder.
                  </p>
                </div>
                <div className="col-lg-7 col-md-12"></div>
              </div>
            </div>
          </div>
          <div id="section8" className="secs" data-aos="fade-up">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 mx-auto text-center">
                  <div className="">
                    <h2 className="fw-bold mb-4 text-white">
                      Downside for Sellers?
                    </h2>
                    <p className="text-white">
                      You can only sell GPUs that meet our Quality Standards.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <div className="mobile-scroll">
        <MobileView/>
      </div>
    </>
  );
};

export default Index;
