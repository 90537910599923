import React, {useState, useEffect} from "react";

const UserContext = React.createContext();



// To add products in Compare Section
export const toggleSelectedProduct = (productId, selectedProducts, setSelectedProducts) => {
    const productIndex = selectedProducts.findIndex((p) => p.id === productId);
  
    if (productIndex !== -1) {
      setSelectedProducts((prevProducts) =>
        prevProducts.filter((p) => p.id !== productId)
      );
    } else {
      setSelectedProducts((prevProducts) => [...prevProducts, productId]);
    }
};

export const toggFavouriteProduct = (productId, newlySelectedProducts, setNewlySelectedProducts) => {
  console.log(
    "set favourate>>>%56"
  )
  const productIndex = newlySelectedProducts.findIndex((p) => p.id === productId);

  if (productIndex !== -1) {
    setNewlySelectedProducts((prevProducts) =>
      prevProducts.filter((p) => p.id !== productId)
    );
  } else {
    setNewlySelectedProducts((prevProducts) => [...prevProducts, productId]);
  }
};


export const AuthProvider = ({children})=>{
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [newlySelectedProducts, setNewlySelectedProducts] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedValue, setSelectedValue] = useState('');

  return (
      <UserContext.Provider value={{selectedProducts, setSelectedProducts, newlySelectedProducts, setNewlySelectedProducts, selectedFilter, setSelectedFilter, selectedValue, setSelectedValue}}>
          {children}
      </UserContext.Provider>
  )
}

export default UserContext;