import React, {useEffect, useState, useContext} from 'react';
import './products.css';
import { Container } from 'react-bootstrap';
import BestDeals from './bestDeals/BestDeals';
import TopPerformer from './topPerformer/TopPerformer';
import MostPopular from './mostPopular/MostPopular';
import RecentlyViewed from './recent/RecentlyViewed';
import UserContext from '../../../context/UserContext';
import axios from 'axios';
import ExportApi from "../../../api/ExportApi";

export default function Products({ productsData, apiEndpoint }) {
    const userId = JSON.parse(localStorage.getItem("tokenuser"))?.id;
    const token = localStorage.getItem("token"); 
    const {setNewlySelectedProducts } = useContext(UserContext);
    useEffect(() => {
        ExportApi.favouriteList(userId)
        //   headers: {
        //     Authorization: `Bearer ${token}`
        //   },
        // })
        .then(function (response) {
          console.log("favourite List", response?.data?.data?.favourite)
          setNewlySelectedProducts(response?.data?.data?.favourite);
        })
        .catch(function (error) {
            console.log(error);
        })
    }, [userId ])
    
    return(
        <div className="products-container">
            <Container>
                <BestDeals productsData={productsData} />
                <TopPerformer productsData={productsData} />
                <MostPopular productsData={productsData} />
                {userId && token && (
                  <RecentlyViewed productsData={productsData} />
                )}
            </Container>
        </div>
    )
}