// import logo from "./logo.svg";
import React, { useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Home from "../src/pages/home";
import Categories from "./pages/categories";
import Product from "./pages/Product";
import Bidask from "../src/pages/bidask";
import BuySell from "./pages/BuySell";
import BuySellManagment from "./pages/BuySellManagment";
//import OrderView from "./pages/OrderView";
import Dashboard from "./pages/Dashboard";
import Footer from "./components/footer"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {

  return (
    <div className="App">
       <ToastContainer/>
      <Home />
      <Categories />
      <Product />
      <Bidask />
      <BuySell />
      <BuySellManagment />
      {/* <OrderView /> */}
      <Dashboard />
      <Footer />
    </div>
  );
}

export default App;
