import React, { useEffect, useState, useRef  } from "react";
import { Form } from "react-bootstrap";
import ExportApi from "../../api/ExportApi";
import Chart from 'chart.js/auto';

export default function Customergraph() {
  const [dateFilter, setDateFilter] = useState();
  const [newday, setNewDay] = useState('Today');
  const [orderPlacedData, setOrderPlacedData] = useState({
    totalCount: 0,
    todayCount: 0,
    weekCount: 0,
    monthCount: 0,
    yearCount: 0,
    yeargraphData: [],
    weeklygraphData: [],
    monthlygraphData: [],
    todaygraphData: []
  });

  const chartRef = useRef(null);

  const handleFilterData = (data) => {
    console.log(data, "data>>123");
    if (data == 1) {
      const newd = "Today"
      setNewDay(newd);
      createChart(orderPlacedData.todaygraphData);
    } else if (data == 7) {
      const newd = "Week"
      setNewDay(newd);
      createChart(orderPlacedData.weeklygraphData);
    } else if (data == 30) {
      const newd = "Month"
      setNewDay(newd);
      createChart(orderPlacedData.monthlygraphData);
    } else if (data == 365) {
      const newd = "Year"
      setNewDay(newd);
      createChart(orderPlacedData.yeargraphData);
    }
  };

  useEffect(() => {
    GetAlluserData();
  }, [])

  useEffect(() => {
    if (orderPlacedData) {
      createChart(orderPlacedData.todaygraphData);
    }
  }, [orderPlacedData])

  const GetAlluserData = () => {
    ExportApi.getallusers()
      .then((resp) => {
        console.log(resp.data, "reap>getall");
        setOrderPlacedData(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(orderPlacedData, "orderPlacedData");
  
  const createChart = (graphData) => {
    if (chartRef.current !== null) {
      chartRef.current.destroy();
    }
  
    let labelKey = 'day';
  
    if (graphData === orderPlacedData.yeargraphData) {
      labelKey = 'month';
    } else if (graphData === orderPlacedData.todaygraphData) {
      labelKey = 'hour';
    } else if (graphData === orderPlacedData.weeklygraphData) {
      labelKey = 'day';
    }
  
    // const ctx = document.getElementById('signupChart').getContext('2d');
    // chartRef.current = new Chart(ctx, {
    //   type: 'line',
    //   data: {
    //     labels: graphData?.map(data => data[labelKey]),
    //     datasets: [{
    //       label: '',
    //       data: graphData?.map(data => data.count),
    //       fill: false,
    //       borderColor: 'rgb(75, 192, 192)',
    //       tension: 0.1
    //     }]
    //   },
    //   options: {
    //     plugins: {
    //       legend: {
    //         display: false
    //       },
    //       tooltip: {
    //         enabled: true
    //       }
    //     },
    //     scales: {
    //       x: {
    //         title: {
    //           display: true,
    //           text: labelKey === 'hour' ? 'Hour of the day' : labelKey === 'day' ? 'Day of the week' : 'Month of the year'
    //         }
    //       },
    //       y: {
    //         title: {
    //           display: true,
    //           text: 'Signups'
    //         }
    //       }
    //     }
    //   }
    // });


    const ctx = document.getElementById('signupChart').getContext('2d');

    // Clear any previous chart instance if it exists
    if (chartRef.current) {
      chartRef.current.destroy();
    }
    
    chartRef.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels: graphData?.map(data => data[labelKey]),
        datasets: [{
          label: '',
          data: graphData?.map(data => data.count),
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }]
      },
      options: {
        responsive: false, // Disable responsiveness if you want fixed size
        maintainAspectRatio: false, // Ensure the chart respects the canvas size
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: true
          }
        },
        scales: {
          x: {
            title: {
              display: true,
              text: labelKey === 'hour' ? 'Hour of the day' : labelKey === 'day' ? 'Day of the week' : 'Month of the year'
            }
          },
          y: {
            title: {
              display: true,
              text: 'Signups'
            }
          }
        }
      }
    });
    

  };
  
  
  
  return (
    <div>
      <div className="d-flex gap-2 justify-content-around align-items-center p-2 bg-dark text-white rounded mt-3">
        <span className="d-block mt-4">
          <Form.Select
            aria-label="Default select example"
            className="border-0 fw-bold mb-2 mb-xl-0 bg-none text-white today-select"
            style={{ width: "155px" }}
            value={dateFilter}
            onChange={(e) => handleFilterData(e.target.value)}
          >
            <option className="text-dark bg-white" value="1">Today</option>
            <option className="text-dark" value="7">Last 7 Days</option>
            <option className="text-dark" value="30">Month</option>
            <option className="text-dark" value="365">Year</option>
          </Form.Select>
        </span>
        <div className="py-2 px-3 rounded text-center">
          <h5 className="text-white fw-bold"></h5>
          <div className="d-sm-flex justify-content-between gap-3">
            <div className="bg-custom-light py-2 px-3 text-center rounded mb-2 mb-xl-0 text-dark">
              <div>
                <h6 className="mb-0 fw-bold">Total Counts</h6>
                <span className=" me-2 fw-bold">{orderPlacedData?.totalCount}</span>
                <span className="text-success"></span>
              </div>
            </div>
            <div className="bg-custom-light py-2 px-3 text-center rounded mb-2 mb-xl-0 text-dark">
              <div>
                <h6 className="mb-0 fw-bold">{newday}</h6>
                {newday === "Today" && <span className="me-2 fw-bold">{orderPlacedData?.todayCount}</span>}
                {newday === "Week" && <span className="me-2 fw-bold">{orderPlacedData?.weekCount}</span>}
                {newday === "Month" && <span className="me-2 fw-bold">{orderPlacedData?.monthCount}</span>}
                {newday === "Year" && <span className="me-2 fw-bold">{orderPlacedData?.yearCount}</span>}
              </div>
            </div>
          </div>
        </div>
        <div className="text-center py-2 px-3 rounded mt-2 mt-sm-0">
          <h5 className="text-white fw-bold">Customers Data</h5>
          <div className="d-sm-flex justify-content-between gap-3">
            <div className="bg-custom-light py-2 px-3 text-center rounded mb-2 mb-xl-0 text-dark">
            <canvas id="signupChart" style={{height: "150px", width: "300px"}}></canvas>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
