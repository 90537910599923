import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Form,
  Button,
  Table,
  Modal
} from "react-bootstrap";
import { IconButton } from "@mui/material";
import ExportApi from "../../../api/ExportApi";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useForm ,Controller } from 'react-hook-form';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../HelpManagement.css"

export default function CategoryList() {

    const [count, setCount] = useState();
    const [categoryData, setCategoryData] = useState();
    const [showModal , setShowModal] = useState(false);
    const [selectedData , setSelectedData] = useState();

    const [error, setError] = useState({
        name : '',
        description : ''
    });

    const handleClose = () => {
        setShowModal(false);
    }

    const handleSingleData = (e, data) =>{
        const filteredObject = categoryData.filter(obj => obj._id === data);
        setSelectedData(filteredObject[0]);
        console.log(filteredObject);
        setShowModal(true);
    }

    const handleEditSubmit = () =>{
        const name = selectedData?.name.trim();
        const description = selectedData?.description.trim();
        const catId = selectedData?._id;

        // Trim the input value to remove leading and trailing whitespaces
        if (!name) {
            setError((prevError) => ({
                ...prevError,  // Copy the existing state
                name: 'Category name is Required',  // Update the specific field
            }));  
            return      
        }

        if (!description) {
            setError((prevError) => ({
                ...prevError,  // Copy the existing state
                description: 'Category description is Required',  // Update the specific field
            }));  
            return      
        }
        
        // console.log(name , "dddddddddddddd" , description);
        // return
        ExportApi.updateHelpCategory(name, description, catId).then((resp)=>{
            console.log(resp?.data);
            const data = resp?.data;
            if(data?.status == 201){
                toast.success(data?.message)
                setCount(1)
                getCategoryData();
                setShowModal(false);
            }else{
                toast.error(data?.message)
                setCount(1)
            }
        }).catch((err)=> console.log(err))
        setCount(1)
    }

    const handleSingleDelete = (data) =>{
          const userConfirmed = window.confirm('Do you want to proceed?');
            if (userConfirmed) {
                ExportApi.deleteHelpCategory(data).then((resp)=>{
                    console.log(resp?.data);
                    const data = resp?.data;
                    if(data?.status == 201){
                        toast.success(data?.message)
                 
                        setCount(1)
                        getCategoryData();
                    }else{
                        toast.error(data?.message)
                        setCount(1)
                    }
                }).catch((err)=> console.log(err))
                setCount(1)
              
            } else {
            console.log("cancel");
            }

    }

    useEffect(() => {
        getCategoryData()
      }, [])

      const getCategoryData = () =>{
        ExportApi.getHelpData('').then((resp)=>{
            console.log(resp?.data);
            const data = resp?.data;
            if(data?.status == 201){
                console.log(data?.detail);
                setCategoryData(data?.detail);
                setCount(1)
            }else{
                toast.error(data?.message)
                setCount(1)
            }
        }).catch((err)=> console.log(err))
        setCount(1)
      }

  return (
    <div className='shipping mt-5'>
    <h2>Categories List</h2>
    <Table striped className="align-middle mt-4">
        <thead>
            <tr>
                <th></th>
                <th>Name</th>
                <th>Description</th>
                <th></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {categoryData?.length > 0 ? (
                categoryData.map((data, i) => {
                    return(
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td><p className="line-clamp">{data.name}</p></td>
                            <td>
                                <p className="line-clamp" dangerouslySetInnerHTML={{ __html: data.description }}></p>
                            </td>
                            <td>
                                <button className='btn d-flex align-items-center' onClick={(e) => {handleSingleData(e, data?._id)}}>Edit<span>
                                <svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 494.936 494.936"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M389.844,182.85c-6.743,0-12.21,5.467-12.21,12.21v222.968c0,23.562-19.174,42.735-42.736,42.735H67.157 c-23.562,0-42.736-19.174-42.736-42.735V150.285c0-23.562,19.174-42.735,42.736-42.735h267.741c6.743,0,12.21-5.467,12.21-12.21 s-5.467-12.21-12.21-12.21H67.157C30.126,83.13,0,113.255,0,150.285v267.743c0,37.029,30.126,67.155,67.157,67.155h267.741 c37.03,0,67.156-30.126,67.156-67.155V195.061C402.054,188.318,396.587,182.85,389.844,182.85z"></path> <path d="M483.876,20.791c-14.72-14.72-38.669-14.714-53.377,0L221.352,229.944c-0.28,0.28-3.434,3.559-4.251,5.396l-28.963,65.069 c-2.057,4.619-1.056,10.027,2.521,13.6c2.337,2.336,5.461,3.576,8.639,3.576c1.675,0,3.362-0.346,4.96-1.057l65.07-28.963 c1.83-0.815,5.114-3.97,5.396-4.25L483.876,74.169c7.131-7.131,11.06-16.61,11.06-26.692 C494.936,37.396,491.007,27.915,483.876,20.791z M466.61,56.897L257.457,266.05c-0.035,0.036-0.055,0.078-0.089,0.107 l-33.989,15.131L238.51,247.3c0.03-0.036,0.071-0.055,0.107-0.09L447.765,38.058c5.038-5.039,13.819-5.033,18.846,0.005 c2.518,2.51,3.905,5.855,3.905,9.414C470.516,51.036,469.127,54.38,466.61,56.897z"></path> </g> </g> </g></svg></span></button>
                            </td>
                            <td>
                                <button className='btn d-flex align-items-center' onClick={(e) => {handleSingleDelete(data?._id)}}>Delete<span>
                                <svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 494.936 494.936"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M389.844,182.85c-6.743,0-12.21,5.467-12.21,12.21v222.968c0,23.562-19.174,42.735-42.736,42.735H67.157 c-23.562,0-42.736-19.174-42.736-42.735V150.285c0-23.562,19.174-42.735,42.736-42.735h267.741c6.743,0,12.21-5.467,12.21-12.21 s-5.467-12.21-12.21-12.21H67.157C30.126,83.13,0,113.255,0,150.285v267.743c0,37.029,30.126,67.155,67.157,67.155h267.741 c37.03,0,67.156-30.126,67.156-67.155V195.061C402.054,188.318,396.587,182.85,389.844,182.85z"></path> <path d="M483.876,20.791c-14.72-14.72-38.669-14.714-53.377,0L221.352,229.944c-0.28,0.28-3.434,3.559-4.251,5.396l-28.963,65.069 c-2.057,4.619-1.056,10.027,2.521,13.6c2.337,2.336,5.461,3.576,8.639,3.576c1.675,0,3.362-0.346,4.96-1.057l65.07-28.963 c1.83-0.815,5.114-3.97,5.396-4.25L483.876,74.169c7.131-7.131,11.06-16.61,11.06-26.692 C494.936,37.396,491.007,27.915,483.876,20.791z M466.61,56.897L257.457,266.05c-0.035,0.036-0.055,0.078-0.089,0.107 l-33.989,15.131L238.51,247.3c0.03-0.036,0.071-0.055,0.107-0.09L447.765,38.058c5.038-5.039,13.819-5.033,18.846,0.005 c2.518,2.51,3.905,5.855,3.905,9.414C470.516,51.036,469.127,54.38,466.61,56.897z"></path> </g> </g> </g></svg></span></button>
                            </td>
                        </tr>
                    )
                })
            )
            :
            <h5 className='text-center'>No Data Found</h5>}
        </tbody>
    </Table>

    <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Details</Modal.Title>
        </Modal.Header>

        {/* <Modal.Body className='shipping-modal'> */}
        <Modal.Body className="scrollable-modal-body">
            <form action="">
            <div className="input-container my-4">
                    <label>Category Name</label>
                    <input type="text" className="form-control" placeholder={selectedData?.name} value={selectedData ? selectedData?.name : "test"} 
                    onChange={(e) => {setSelectedData((prevData) => ({
                            ...prevData,
                            name: e.target.value,
                            }));
                            setError((prevData) => ({
                                ...prevData,
                                // Update the specific field in the other state
                                name: "",
                                }));
                             }
                            }
                           />
                    {error?.name && <p style={{ color: 'red' }}>{error?.name}</p>}
                </div>
                {/* <div className="input-container my-4"> */}
                <CKEditor
                        editor={ClassicEditor}
                        data={selectedData ? selectedData?.description : ""}  // S
                        onChange={(event, editor) => {
                        const data = editor.getData();
                        setSelectedData((prevData) => ({
                            ...prevData,
                            description: data,
                            }));
                              // Update another state (assuming anotherState is your other state)
                        setError((prevData) => ({
                        ...prevData,
                        // Update the specific field in the other state
                        description: "",
                        }));
                        }}                        
                    />
                     {error?.description && <p style={{ color: 'red' }}>{error?.description}</p>}
                     {/* </div> */}

            </form>
            <div className="buttons d-flex justify-content-center mt-4">
                <button className='btn btn-primary me-4' onClick={handleEditSubmit}>Save</button>
                <button className='btn btn-danger' onClick={() => {handleClose()}}>Cancel</button>
            </div>
        </Modal.Body>
      </Modal>
</div>
  )
}
