import React from "react";

export default function UserBuybaner() {
  return (
    <div>
      <div className="buybanner py-5">
        <h1 className="text-center text-white">Graphics Card</h1>
      </div>
    </div>
  );
}
