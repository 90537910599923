import React, { useEffect, useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
// import "./List.css";
import ExportApi from "../../api/ExportApi";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";



function List() {
    const [featurednews, setFeaturedNews] = useState([]);
    const [newfeatured, setNewFeatured] = useState([]);
    const [unfeaturednews, setUnFeaturedNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [clickCount, setClickCount] = useState(0);
    const [keyword, setKeyword] = useState('');
    const [searchResults, setSearchResults] = useState([]);


    const navigate = useNavigate();
    const fetchNewsList = async () => {
        try {
            setLoading(true);
            const response = await ExportApi.getAllnews();
            console.log(response, "respons>>>>>>>>>>123");
            const filteredNewsList = response.data.data.filter(news => news.featured === true);
            const filteredList = filteredNewsList.slice(1).map((news, index) => ({ ...news, index: index + 1 }));

            const unfilteredNewsList = response.data.data.filter(news => news.unfeatured === true);
            console.log(unfilteredNewsList,"unfilteredNewsList123");
            setLoading(false);
            setUnFeaturedNews(unfilteredNewsList);
            setFeaturedNews(filteredNewsList);
            setNewFeatured(filteredList);
        } catch (error) {
            console.error("Error fetching news list:", error);
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchNewsList();
    }, []);


    useEffect(() => {
        const count = localStorage.getItem('pageCount');
        if (!count) {
            localStorage.setItem('pageCount', 1);
        } else {
            const newCount = parseInt(count) + 1;
            localStorage.setItem('pageCount', newCount);

            // Make a POST request to the API endpoint with the count data
            axios.post('your-api-endpoint', { count: newCount })
                .then(response => {
                    // Handle successful response if needed
                    console.log('Count saved successfully:', response.data);
                })
                .catch(error => {
                    // Handle error if needed
                    console.error('Error saving count:', error);
                });
        }
    }, []);

    let startTime = new Date();
    const handleClick = async (newsItemId) => {
        console.log("hit this up");
        try {
            navigate(`/news/${newsItemId}`);

            const endTime = new Date();
            const timeDifference = endTime - startTime;
            const averageTime = timeDifference / (clickCount + 1); // Increment clickCount

            const averageTimeInMinutes = averageTime / 60000;
            console.log(averageTimeInMinutes, "averageTimeInMinutes");
            const averageTimeInSeconds = averageTimeInMinutes * 60;
            console.log(averageTimeInSeconds, "averageTimeInSeconds");

            const averageTimeRounded = averageTimeInSeconds.toFixed(2);
            const averageTimes = parseFloat(averageTimeRounded);
            const data = {
                averageTime: averageTimes,
                totalCount: clickCount + 1
            };

            console.log(newsItemId, "newsItemId11");
            console.log(data, "data321");


            const response = await ExportApi.totalcount(newsItemId, data);

            if (response.ok) {
                console.log('Data posted successfully');
            } else {
                // Handle API errors
                console.error('Failed to post data:', response.statusText);
            }

            setClickCount(prevCount => prevCount + 1); // Update clickCount
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        const handleSearch = async () => {
            try {
                const response = await ExportApi.newssearch({ keyword });
                console.log(response.data.data, "response.data");
                setSearchResults(response.data.data);
            } catch (error) {
                console.error('Error searching for news:', error);
            }
        };

        const delayDebounceFn = setTimeout(() => {
            if (keyword) {
                handleSearch();
            } else {
                setSearchResults([]); // Reset searchResults to an empty array when keyword is empty
            }
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [keyword]);

    console.log(searchResults, "searchResults>>")
    return (
        <>
            <Header />
            {/* <div className='search'>
                            <div className="input-group">
                                <span class="input-group-text" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="bi bi-search" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path></svg>
                                </span>
                                <input placeholder="Search News" autocomplete="false" type="text" className="form-control" value="" />
                            </div>
                        </div> */}

            {loading ? <div className="loader-icon" style={{ marginBlock: "80px" }}>
                <i className="fa-solid fa-spinner fa-spin-pulse"></i>
            </div> : <>
                <div className='search-bar py-5' style={{ background: "#f9fafb" }}>
                    <Container>
                        <>
                            <Row>

                                <div className='search'>

                                    <div className="input-group">
                                        <span className="input-group-text" id="basic-addon1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="bi bi-search" viewBox="0 0 16 16">
                                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                                            </svg>
                                        </span>
                                        <input
                                            placeholder="Search News"
                                            autoComplete="false"
                                            type="text"
                                            className="form-control"
                                            value={keyword}
                                            onChange={(e) => setKeyword(e.target.value)}
                                        />
                                    </div>
                                    {searchResults.length > 0 ? (
                                        <div className="custom-news">

                                            {searchResults?.map((newsItem) => (

                                                <div className="custom-newsbar" key={newsItem?._id}>
                                                    <img
                                                        src={newsItem?.image}
                                                        alt={newsItem?.heading}
                                                        style={{ width: '100px', height: '100px', borderRadius: '5px' }}
                                                        onClick={() => handleClick(newsItem?._id)}
                                                    />
                                                    <div className="" onClick={() => handleClick(newsItem?._id)} style={{ cursor: 'pointer' }}>
                                                        <h3>{newsItem?.heading}</h3>
                                                        <p dangerouslySetInnerHTML={{ __html: newsItem?.description }} />
                                                            {/* {newsItem?.description}
                                                        
                                                        </p> */}
                                                    </div>

                                                </div>

                                            ))}

                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                            </Row>
                        </>
                    </Container>
                </div>

                <div className="pb-5 pt-2" style={{ background: "#f9fafb" }}>
                    <Container>
                        {/* first */}
                        <Row className="d=flex justify-content-center flex-wrap align-items-center p-3 bg-white grid-list mb-5">
                            <Col xs={12} md={6} className="ps-0">
                                <div className="news-grid"
                                    //  onClick={() => navigate(`/news/${featurednews[0]?._id}`)}
                                    onClick={() => handleClick(featurednews[0]?._id)}
                                >
                                    <img className="w-100" style={{ width: '300px', height: '300px'}} src={featurednews[0]?.image} alt={featurednews[0]?.heading} />
                                </div>
                            </Col>
                            <Col xs={12} md={6} className="pe-0">
                                <div className="news-grid my-3 me-5"
                                    //  onClick={() => navigate(`/news/${featurednews[0]?._id}`)}
                                    onClick={() => handleClick(featurednews[0]?._id)}
                                >
                                    <p className="New-category">{featurednews[0]?.categories[0]?.name}</p>
                                    <h3 className="mb-3 text-dark fw-bold fs-4"> {featurednews[0]?.heading} </h3>
                                    <p className="fs-6 fw-normal"
                                     dangerouslySetInnerHTML={{ __html: featurednews[0]?.description.replace(/<h[^>]*>|<img[^>]*>|<\/?strong[^>]*>|<\/?a[^>]*>/g, '') }}
                                    /> 
                                    {/* </div>{featurednews[0]?.description} </p> */}
                                </div>
                            </Col>
                        </Row>
                        {/* second & third */}
                        <Row className="mb-5">

                            {newfeatured?.map((newsItem, index) => (

                                <Col key={index} xs={12} md={6}>
                                    <div className="news-grid-box">
                                        <div className="">
                                            <div className="news-grid"
                                                // onClick={() => navigate(`/news/${newsItem?._id}`)}
                                                onClick={() => handleClick(newsItem?._id)}
                                            >
                                                <img className="w-100" style={{ width: '200px', height: '200px'}} src={newsItem?.image} alt={newsItem?.heading} />
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="news-grid"
                                                //onClick={() => navigate(`/news/${newsItem?._id}`) }
                                                onClick={() => handleClick(newsItem?._id)}
                                            >
                                                <p className="New-category mb-2">{newsItem?.categories[0]?.name}</p>
                                                <h3 className="mb-3 text-dark fw-bold fs-5">{newsItem?.heading}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            ))}


                        </Row>
                    </Container>


                    {/* unfeaturednews */}
                    <div className="bg-white py-5">
                        <Container>
                            <Row className="mt-3">
                                {unfeaturednews?.map((newsItem, index) => (
                                    <Col key={index} xs={12} md={4} className="mb-3">
                                        <div className="news-grid"
                                            //onClick={() => navigate(`/news/${newsItem?._id}`)}
                                            onClick={() => handleClick(newsItem?._id)}
                                        >
                                            <img className="w-100 mb-3" style={{ width: '300px', height: '300px'}} src={newsItem?.image} alt={newsItem?.heading} />
                                            <h3 className="mb-3 text-dark fw-bold fs-5">{newsItem?.heading}</h3>
                                            <p className="fs-6 fw-normal" dangerouslySetInnerHTML={{ __html: newsItem?.description.replace(/<h[^>]*>|<img[^>]*>|<\/?strong[^>]*>|<\/?a[^>]*>/g, '') }} />
                                                {/* {newsItem?.description}  */}
                                            
                                            {/* </p> */}
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                            {/* <button className="all-post-btn" >Browse all posts</button> */}
                            <Link to="/news" className="all-post-link">
                                <button className="all-post-btn">Browse all posts</button>
                            </Link>

                        </Container>
                    </div>

                </div>
            </>
            }




            <Footer />
        </>
    )
}

export default List
