import React from 'react';
import CustomerMain from "../../Admin/Customer/CustomerMain";
import Customergraph from './Customergraph';
 


export default function Customerview() {
    return (
        <div>
        
            <Customergraph />
            <CustomerMain />
          
        
           
        </div>
    )
}
