// Import React and necessary dependencies
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Button, Container } from "react-bootstrap"; // Import Button and Container components from react-bootstrap
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Xing from "../images/xing.png"
import Facebook from "../images/facebook.png"
import Insta from "../images/insta.png"
import Tiktok from "../images/tik-tok.png"
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Modal, ListGroup } from "react-bootstrap";
import Login from "./Login";
import Signup from "./Signup";
import TawkTo from 'tawkto-react';
import NewSignup from "./NewSignup";
import ExportApi from "../../src/api/ExportApi";
import { toast } from "react-toastify";



// Define and export the Footer component
export default function Footer(props) {


  // const [signup, setSignup] = useState(false);
  const [show, setShow] = useState(false);
  const [shownew, setShownew] = useState(false);


  const [token, setToken] = useState(localStorage.getItem("tokenuser"));

  const [userId, setUserId] = useState(JSON.parse(localStorage.getItem("tokenuser"))?.id);
  const [email, setEmail] = useState('');
  //const [initialized, setInitialized] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // const contactus = new URLSearchParams(location.search);
  // const paramValue = contactus.get("contactus")
  // console.log(paramValue,"quertparmas>>>>>>>>>>>>>27");


  const handleShow = () => {
    // Check if a user token exists in localStorage
    if (!localStorage.getItem("tokenuser")) {

      setShow(true);
    } else {

      setShow(false);
      setToken(localStorage.getItem("tokenuser"));
      setUserId(JSON.parse(localStorage.getItem("tokenuser")).id);

    }
  };

  const newhandleShow = () => {


    setShownew(true);

  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  // const handleShownew = () => {
  //   console.log("notloginnnnnn");
  // };
  const handleClose = () => setShow(false);

  const handleClosenew = () => setShownew(false);




  const openTawkToPopup = () => {
    const tawkToPropertyId = '6583dce370c9f2407f8220c3';
    const tawkToKey = '1hi5hdmtr';
    let tawkToInstance = new TawkTo(tawkToPropertyId, tawkToKey);
    tawkToInstance.toggle();
    console.log("hit meeee");
  };

  const tokenUser = localStorage.getItem("tokenuser");
  const tokenAdmin = localStorage.getItem("admin");
  // console.log(tokenUser, "tokenUser123");
  // console.log(tokenAdmin, "tokenAdmin123");


  const userIdnew = JSON.parse(localStorage.getItem("tokenuser"));

  JSON.parse(localStorage.getItem("admin"));



  const contactus = new URLSearchParams(location.search);
  const paramValue = contactus.get("contactus");
  const tokenpic = localStorage.getItem("load");
  // && tokenpic === 'true'
  // console.log(tokenpic,"tokenpic");
  if (paramValue === 'true' && tokenpic === 'true') {
    // const tawkToPropertyId = '6583dce370c9f2407f8220c3';
    // const tawkToKey = '1hi5hdmtr';
    // let tawkToInstance = new TawkTo(tawkToPropertyId, tawkToKey);
    //  tawkToInstance.toggle();
    //  setTimeout(() => {
    //   openTawkToPopup()
    //  }, 200);
    openTawkToPopup()
  }

  // useEffect(() => {
  //   const contactus = new URLSearchParams(location.search);
  //   const paramValue = contactus.get("contactus");
  // console.log(paramValue,"paramValue1")

  // if (paramValue === 'true') {
  // const load = localStorage.getItem('load') === 'true';

  // if (load) {
  // const tawkToPropertyId = '6583dce370c9f2407f8220c3';
  // const tawkToKey = '1hi5hdmtr';
  // const tawkToInstance = new TawkTo(tawkToPropertyId, tawkToKey);
  // tawkToInstance.toggle();
  // console.log("testing");
  // openTawkToPopup();

  // }
  //   }
  // }, []);

  //   const openTawkToPopup = () => {
  //     const tawkToPropertyId = '6583dce370c9f2407f8220c3';
  //     const tawkToKey = '1hi5hdmtr';
  //     let tawkToInstance = new TawkTo(tawkToPropertyId, tawkToKey);
  //     tawkToInstance.toggle();
  //     localStorage.setItem('tawkToOpen', true); // Set Tawk.to state to open
  // };

  // const isTawkToOpen = localStorage.getItem('tawkToOpen') === 'true';

  // useEffect(() => {

  //   TawkTo.showWidget();

  //     const contactus = new URLSearchParams(location.search);
  //     const paramValue = contactus.get("contactus");
  //     if (paramValue === 'true' && !isTawkToOpen) {
  //         console.log("testing");
  //         openTawkToPopup();
  //         localStorage.setItem('tawkToOpen', true); // Set Tawk.to state to open
  //     }
  // }, []);

  //   useEffect(() => {
  //     const tawkToPropertyId = '6583dce370c9f2407f8220c3';
  //     const tawkToKey = '1hi5hdmtr';

  //     const tawkToInstance = new TawkTo(tawkToPropertyId, tawkToKey);
  //     tawkToInstance.toggle();
  // }, []);








  // const unsubscribe = new URLSearchParams(location.search);
  // const newparamValue = unsubscribe.get("unsubscribe");
  // console.log(newparamValue, "newparamValue>>>>>>>>>>>>>113");
  // if (newparamValue === 'true' ){
  //   const email = unsubscribe.get("email");
  //        console.log(email,"unsubscribe123");
  //        setTimeout(() => {
  //        ExportApi.unsubscribedata(email)
  //        .then((response) => {
  //            console.log("Unsubscribe API response:", response);
  //            toast.success(response.data.message)

  //        })
  //        .catch((error) => {
  //            console.error("Error unsubscribing:", error);
  //            toast.error("Error in unsubscribing")

  //        });
  //       }, 2000); 
  // }

  // console.log(userIdnew?.id, "userIdnew>>>>>>>>>>>>>68");
  return (
    <div className="footer" id="footer" style={{ background: "#f4f4f4" }}>
      <Container>
        <Row className="footer-signup-form">
          <div className="d-flex align-items-start justify-content-center flex-wrap w-100">
            <div className="footer-title-custom">
              <h4 className="fw-semibold fs-5 mb-3 mt-2 me-3 text-dark lh-sm">Sign up for News and special offers:</h4>
            </div>
            <div className="subscribe-item" style={{ width: "40%" }}>
              <Form className="d-flex gap-3">
                <Form.Group controlId="formBasicEmail" className="w-100">
                  <Form.Control className="p-2" type="email" placeholder="Enter Email" onChange={handleEmailChange} />
                </Form.Group>
                {/* <Button className="signup-btn text-nowrap"
                  onClick={() => handleShow()}
                  
                  variant="primary" type="submit">
                    Sign Up
                  </Button> */}

                {(tokenUser || tokenAdmin) ? (
                  <Button className="signup-btn text-nowrap"
                    // onClick={() => handleShownew()}

                    variant="primary"
                  //  type="submit"
                  >
                    Sign Up
                  </Button>) : (
                  <Button className="signup-btn text-nowrap"
                    onClick={() => handleShow()}
                    variant="primary"
                  // type="submit"
                  >
                    Sign Up
                  </Button>
                )}

              </Form>
              <Link to={userIdnew?.id === undefined ? "" : `/profile/${userIdnew?.id}`}>
                <p className="fw-normal text-dark mb-0" style={{ fontSize: "18px" }}>Manage Existing Subscriptions</p>
              </Link>
            </div>
          </div>

        </Row>
      </Container>

      <Container className="py-4">
        <span className="custom-border"></span>
        <Row>
          <Col xs={12} md={4}>
            <div className="nav-list mb-3 ms-5">
              <div className="d-flex flex-column">
                {/* <Link to={userIdnew?.id === undefined ? "" : `/profile/${userIdnew?.id}`} className="fs-5 fw-medium text-dark">My Account</Link> */}
                {/* {console.log(userIdnew?.id, "userIdnew?.id>>>>>>>>123")} */}
                {
                  userIdnew?.id === undefined ?
                    <Link onClick={newhandleShow} className="fs-5 fw-bold text-dark mb-2">My Account</Link> :
                    <Link to={`/profile/${userIdnew?.id}`} className="fs-5 fw-bold text-dark mb-2">My Account</Link>
                }

              </div>


              <div className=" d-flex flex-column">
                {/* <Link to={userIdnew?.id === undefined ? "" : "/buySellManagment/selling"} className="fs-5 fw-medium text-dark">Selling</Link> */}
                {
                  userIdnew?.id === undefined ?
                    <Link onClick={newhandleShow} className="fs-5 fw-medium text-dark ">Selling</Link> :
                    <Link to={"/buySellManagment/selling"} className="fs-5 fw-medium text-dark">Selling</Link>
                }
                {/* <Link to={userIdnew?.id === undefined ? "" : "/buySellManagment/buying"} className="fs-5 fw-medium text-dark">Buying</Link> */}
                {
                  userIdnew?.id === undefined ?
                    <Link onClick={newhandleShow} className="fs-5 fw-medium text-dark ">Buying</Link> :
                    <Link to={"/buySellManagment/buying"} className="fs-5 fw-medium text-dark">Buying</Link>
                }

                {/* <Link to={userIdnew?.id === undefined ? "" : "/buySellManagment/pending"} className="fs-5 fw-medium text-dark">Pending</Link> */}
                {
                  userIdnew?.id === undefined ?
                    <Link onClick={newhandleShow} className="fs-5 fw-medium text-dark ">Pending</Link> :
                    <Link to={"/buySellManagment/pending"} className="fs-5 fw-medium text-dark">Pending</Link>
                }
                {/* <Link to={userIdnew?.id === undefined ? "" : "/buySellManagment/history"} className="fs-5 fw-medium text-dark">History</Link> */}
                {
                  userIdnew?.id === undefined ?
                    <Link onClick={newhandleShow} className="fs-5 fw-medium text-dark ">History</Link> :
                    <Link to={"/buySellManagment/history"} className="fs-5 fw-medium text-dark">History</Link>
                }
                {/* <Link to="" className="fs-5 fw-medium text-dark">Message</Link> */}
                {/* <Link to={userIdnew?.id === undefined ? "" : `/profile/${userIdnew?.id}`} className="fs-5 fw-medium text-dark">User Info</Link> */}
                {
                  userIdnew?.id === undefined ?
                    <Link onClick={newhandleShow} className="fs-5 fw-medium text-dark ">User Info</Link> :
                    <Link to={`/profile/${userIdnew?.id}`} className="fs-5 fw-medium text-dark">User Info</Link>
                }
                {/* <Link to={userIdnew?.id === undefined ? "" : `/stripeconnectaccount/${userIdnew?.id}`} className="fs-5 fw-medium text-dark">Payments and Payouts</Link> */}
                {
                  userIdnew?.id === undefined ?
                    <Link onClick={newhandleShow} className="fs-5 fw-medium text-dark ">Payments and Payouts</Link> :
                    <Link to={`/stripeconnectaccount/${userIdnew?.id}`} className="fs-5 fw-medium text-dark">Payments and Payouts</Link>
                }
              </div>
            </div>
          </Col>
          {/* social medias */}
          <Col xs={12} md={4} className="center-section d-flex align-items-center justify-content-center flex-column">
            <ul className="list-unstyled d-flex align-items-center mb-5">
              {/* <li className="me-4">
                <Link to="https://twitter.com/gpu_wiz" ><img src={Xing} /></Link>
              </li> */}
              <li className="me-4">
                <a href="https://twitter.com/gpu_wiz" target="_blank" rel="noopener noreferrer">
                  <img src={Xing} alt="Twitter" />
                </a>
              </li>
              {/* <li className="me-4">
                <Link to="https://www.instagram.com/gpuwiz/" ><img src={Insta} /></Link>
              </li> */}
              <li className="me-4">
                <a href="https://www.instagram.com/gpuwiz/" target="_blank" rel="noopener noreferrer">
                  <img src={Insta} alt="Instagram" />
                </a>
              </li>
              {/* <li className="me-4">
                <Link to="https://www.tiktok.com/@gpuwiz?lang=en"><img src={Tiktok} /></Link>
              </li> */}
              <li className="me-4">
                <a href="https://www.tiktok.com/@gpuwiz?lang=en" target="_blank" rel="noopener noreferrer">
                  <img src={Tiktok} alt="TikTok" />
                </a>
              </li>
              {/* <li>
                <Link to="https://www.facebook.com/profile.php?id=61556017664988" ><img src={Facebook} /></Link>
              </li> */}
              <li>
                <a href="https://www.facebook.com/profile.php?id=61556017664988" target="_blank" rel="noopener noreferrer">
                  <img src={Facebook} alt="Facebook" />
                </a>
              </li>

            </ul>
            <p className="text-center fw-bold text-dark fs-5 mb-2">Los Angeles, CA</p>
            <p className="text-center fw-medium text-dark fs-5">©2024 gpuwiz | All Rights Reserved.</p>
          </Col>
          <Col xs={12} md={4}>
            <div className="nav-list mb-0 ms-5">
              <div className="mb-2 fs-5 fw-bold">
                <Link
                  onClick={() => {
                    localStorage.setItem("activeText", "Quality Standards");
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}

                  to="/help"

                  className="d-inline text-dark fs-5 fw-bold">Help</Link></div>
              <div className="d-flex flex-column">
                <Link
                  onClick={() => {
                    localStorage.setItem("activeText", "Quality Standards");
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}


                  to="/QualityStandards"
                  className="d-inline text-dark fs-5 fw-medium">Quality Standards</Link>
                <Link
                  onClick={() => {
                    localStorage.setItem("activeText", "Pre-Sale Inspection Guide");
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}

                  to="/PreSaleInspectionGuide" className="d-inline text-dark fs-5 fw-medium">Pre-Sale Inspection Guide</Link>
                <Link
                  onClick={() => {
                    localStorage.setItem("activeText", "Buying and Selling Guide");
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}

                  to="/BuyingandSellingGuide" className="d-inline text-dark fs-5 fw-medium">Buying and Selling Guide</Link>
                <Link
                  onClick={() => {
                    localStorage.setItem("activeText", "activeText", "Shipping Instructions");
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}

                  to="/ShippingInstructions" className="d-inline text-dark fs-5 fw-medium">Shipping Instructions</Link>
                <Link to="/terms" onClick={() => {

                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                  className="d-inline text-dark fs-5 fw-medium">Terms of Service</Link>
                <Link to="/PrivacyPolicy"
                  onClick={() => {

                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                  className="d-inline text-dark fs-5 fw-medium">Privacy Policy</Link>
                <Link to="/cookies"
                  onClick={() => {

                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                  className="d-inline text-dark fs-5 fw-medium">Cookie Policy</Link>
                <button onClick={openTawkToPopup} className="text-start bg-none border-0 p-0 d-inline text-dark fs-5 fw-medium">Contact Us</button>
                {/* {/ <Link to="" onClick={openTawkToPopup} className="d-inline text-dark fs-5 fw-medium">Contact Us</Link> /} */}
              </div>
            </div>
          </Col>

        </Row>
      </Container>

      {/* signup */}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop={true}
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="scrollable-modal-body">

          {/* <Login navigate={navigate} modal={handleClose}  /> */}
          <NewSignup navigate={navigate} modal={handleClose} email={email} />

        </Modal.Body>
      </Modal>
      {/* <Signup modal={props.modal} navigate={props.navigate} email={email} /> */}

      {/* 
   login in  */}
      <Modal
        show={shownew}
        onHide={handleClosenew}
        backdrop={true}
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="scrollable-modal-body">

          <Login navigate={navigate} modal={handleClosenew} />
        </Modal.Body>
      </Modal>
    </div>

  );
}
