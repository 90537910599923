import React from 'react';
import { Modal } from "react-bootstrap";

function FilterModal({ showFilterModel, filterList, handleCheckboxClick, selectedFilters, hideModal }) {
  return (
    <Modal
      show={showFilterModel}
      onHide={hideModal}
      backdrop={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Product Filter</Modal.Title>
      </Modal.Header>
     <Modal.Body className="scrollable-modal-body">

        <div className="mt-sm-5 mt-3 border mb-sm-5">
          <div className="sidebar-accordian p-3">
            <div>
              <span>
                <b>Condition</b>
              </span>
              {filterList?.condition?.map((type, i) => {
                return (
                  <div className="mb-2">
                    <input type="checkbox" className='form-check-input' onChange={() => handleCheckboxClick('condition', type)} checked={selectedFilters?.condition?.includes(type)} />
                    <label className='form-check-label ms-2 mb-0'>{type == 1 ? "New Retail" : type == 2 ? "New 2nd Hand" : "Used"}</label>
                  </div>
                )
              })}
            </div>

            <div>
              <span>
                <b>Chipset</b>
              </span>
              {filterList?.chipset?.map((type, i) => {
                return (
                  <div className="mb-2">
                    <input type="checkbox" className='form-check-input' onChange={() => handleCheckboxClick('chipset', type)} checked={selectedFilters?.chipset?.includes(type)} />
                    <label className='form-check-label ms-2 mb-0'>{type}</label>
                  </div>
                )
              })}
            </div>

            <div>
              <span>
                <b>Brand</b>
                {filterList?.brands?.map((type, i) => {
                  return (
                    <div className="mb-2">
                      <input type="checkbox" className='form-check-input' onChange={() => handleCheckboxClick('brands', type)} checked={selectedFilters?.brands?.includes(type)} />
                      <label className='form-check-label ms-2 mb-0'>{type}</label>
                    </div>
                  )
                })}
              </span>
            </div>

            <div>
              <span>
                <b>Series</b>
                {filterList?.series?.map((type, i) => {
                  return (
                    <div className="mb-2">
                      <input type="checkbox" className='form-check-input' onChange={() => handleCheckboxClick('series', type)} checked={selectedFilters?.series?.includes(type)} />
                      <label className='form-check-label ms-2 mb-0'>{type}</label>
                    </div>
                  )
                })}
              </span>
            </div>

            <div>
              <span>
                <b>Model</b>
                {filterList?.model?.map((type, i) => {
                  return (
                    <div className="mb-2">
                      <input type="checkbox" className='form-check-input' onChange={() => handleCheckboxClick('model', type)} checked={selectedFilters?.model?.includes(type)} />
                      <label className='form-check-label ms-2 mb-0'>{type}</label>
                    </div>
                  )
                })}
              </span>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default FilterModal;