import React, { useState, useContext, useEffect } from 'react';
import UserContext from '../../../../context/UserContext';
// import Login from '../../../login/Login';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ExportApi from "../../../../api/ExportApi";
import Login from '../../../../components/Login';
import { Modal } from 'react-bootstrap';

export default function ProductItem({
  brand,
  thumbnail,
  title,
  series,
  model,
  price,
  id,
  data,
  chipset,
  condition
}) {
  const { selectedProducts, setSelectedProducts, newlySelectedProducts, setNewlySelectedProducts } = useContext(UserContext);
  const [isClicked, setIsClicked] = useState(false);
  const navigate = useNavigate();
  const userId = JSON.parse(localStorage.getItem("tokenuser"))?.id;
  const token = localStorage.getItem("token");
  const [openModal, setOpenModal] = useState(false);

  // const [show, setShow] = useState(false);


  // Adding Products in newlySelectedProducts state
  useEffect(() => {
    setIsClicked(newlySelectedProducts?.some((product) => product?._id === data?._id));
  }, [newlySelectedProducts, data?._id]);


  
  
  // Add products in Favourite
  const handleClick = (e) => {
    e.preventDefault();
    if (userId && token) {
      setIsClicked(!isClicked);
      if(isClicked === false) {
        ExportApi.AddtoFevreat(id ,userId)
        // axios.post(ExportApi + `user/addtofav/${userId}`, {
        //   productId: id
        // }, {
        //   headers: {
        //     Authorization: `Bearer ${token}`
        //   }
        // },)
          .then(function (response) {
            // console.log("Favourite", response);
            
            setIsClicked(true)
            ExportApi.favouriteList(userId)
            // axios.get(ExportApi + `user/fav/list/${userId}`, {
            //   headers: {
            //     Authorization: `Bearer ${token}`
            //   },
            // })
            .then(function (response) {
              // console.log("favourite List", response.data.data.favourite)
              setNewlySelectedProducts(response.data.data.favourite);
            })
            .catch(function (error) {
                console.log(error);
            })
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      else {
        ExportApi.RemovetoFevreat(id ,userId)
        //   productId: id
        // }, {
        //   headers: {
        //     Authorization: `Bearer ${token}`
        //   }
        // },)
          .then(function (response) {
            console.log("Favourite Remove", response);
            ExportApi.favouriteList(userId)
            //   headers: {
            //     Authorization: `Bearer ${token}`
            //   },
            // })
            .then(function (response) {
              console.log("favourite List", response?.data?.data?.favourite)
              setNewlySelectedProducts(response?.data?.data?.favourite);
            })
            .catch(function (error) {
                console.log(error);
            })
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
    else {
      setOpenModal(true)
    }
  };

  const handleProductClick = (e) => {
    e.preventDefault();
    // if (userId && token) {
      navigate(`/product/${id}`)
  //   }
  //   else {
  //     setOpenModal(true)
  //   }
  }
  // console.log(selectedProducts)
  useEffect(() => {
    if(selectedProducts?.length > 0) {
      localStorage.setItem("Compare", JSON.stringify(selectedProducts));
    }
  }, [selectedProducts])
  // Add Products to Compare
  const handleCheckboxChange = () => {
    const isSelected = selectedProducts.some((product) => product._id === data._id);

    if (isSelected) {
      setSelectedProducts((prevProducts) =>
        prevProducts.filter((product) => product._id !== data._id)
      );
    
    } else {
      setSelectedProducts([...selectedProducts, data]);
    }
  };

  // Close Login Modal
  const handleCloseModal = () => {
    setTimeout(() => {
      setOpenModal(false); 
    }, 300);
  };

  // Favourite svg style
  const svgStyle = {
    width: '20px',
    height: '20px',
    fill: isClicked ? 'red' : 'transparent',
    stroke: 'black',
    strokeWidth: isClicked ? '0' : 1,
    cursor: 'pointer',
  };

  // console.log("openModal", openModal)

    // Function to handle closing a component or dialog
    const handleClose = () => setOpenModal(false);

  return (
    <div className="mb-3 col-sm-6 col-md-3 col-xl-2 col-12 position-relative" key={id}>
      <div className="product-box p-3 position-relative">
        <span className='d-flex position-absolute' style={{ color: chipset === 'AMD' ? 'rgb(186, 0, 21)' : chipset === 'Nvidia' ? 'rgb(114, 179, 0)' : 'inherit' }}>{chipset}</span>
        <div className='d-flex flex-sm-column mt-2 mt-xl-3 w-100'>
          <div onClick={(e) => handleProductClick(e)}>
            <img src={thumbnail[0]} className='m-sm-auto' alt="thumbnail" />
          </div>
          <div className="product-details d-flex justify-content-between w-100 pt-3 ms-2 ms-md-0">
            <div className="desc w-100" onClick={(e) => handleProductClick(e)}>
              <p className='text-start mb-0'>{title}</p>
              <p className='text-start mb-0'>{brand}</p>
              <p className='text-start mb-0'>{series}</p>
              <p className='text-start mb-0 bestdealsmodel' title={model}>{model}</p>
              <p className='text-start fw-bold condition'>Condition: {condition === 1 ? "New-Retail" : condition === 2 ? "New-2nd Hand" : "Used"}</p>
              <h5 className='text-center fw-bold mt-2 mt-lg-3'>
                 {/* {console.log(price,"price343")} */}
                {/* ${price} */}
                { price == "TBD" ? "TBD" : "$"+price }
                </h5>
            </div>
            <svg
              viewBox="0 0 17 17"
              className="sc-beqWaB ixusxy hear-icon"
              xmlns="http://www.w3.org/2000/svg"
              style={svgStyle}
              onClick={(e) =>handleClick(e)}
            >
              <path fillRule="evenodd" d="M8.5,2.3C12.9-2.2,24,5.7,8.5,16C-7,5.7,4.1-2.2,8.5,2.3z"></path>
            </svg>
          </div>
          <div className="compare text-end">
            <label htmlFor={`compare-${data._id}`}>Compare</label>
            <input
              type="checkbox"
              id={`compare-${data._id}`}
              checked={selectedProducts.some((product) => product._id === data._id)}
              onChange={handleCheckboxChange}
              className='ms-2'
            />
          </div>
        </div>
      </div>
          {openModal &&      
          <> 
          {/* Login PopUp Start */}
      <Modal
        show={openModal}
        onHide={handleClose}
        backdrop={true}
        keyboard={false}
      >
        <Modal.Header closeButton></Modal.Header>
       <Modal.Body className="scrollable-modal-body">

          <Login navigate={navigate} modal={handleClose} />
        </Modal.Body>
      </Modal>
      {/* Login Popup End */}

      {/* <div className={openModal ? "addOverlay" : ""}></div> */}
      </>
      }
  
    </div>
  );
}
