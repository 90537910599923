import React from "react";
import { useState } from "react";
import ExportApi from "../api/ExportApi";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import { MdOutlineKeyboardArrowLeft } from "react-icons/md";

const ForgotPassword = (props) => {
  const [email, setEmail] = useState();
  const [isLoading, setLoading] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const handleForgotPassword = () => {
    setLoading(true);
    if (email == "" || email == null) {
      toast.error("Please enter an email address");
      setLoading(false);
    } else if (!validateEmail(email)) {
      setIsValidEmail(false);
      toast.error("Please Enter Valid Email");
      setLoading(false);
    } else {
      ExportApi.ForgotPassword(email)
        .then((resp) => {
          if (resp.data.message == "Email not found") {
            toast.error("Email not found");
            setLoading(false);
          } else {
            setTimeout(() => {
              props.modal ? props.modal() : "";
              setLoading(false);
            }, 100);
            toast.success("Forgot password link has been sent Successfully");
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleEmail = (value) => {
    setEmail(value);
    setIsValidEmail(true);
  };

  const validateEmail = (email) => {
    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleLogin = () => {
    setStap(true);
    setSignup(false);
    setForgot(false);
  };
  return (
    <div>
      <div>
        <h4 className="text-center cursor-pointer">Forgot Password</h4>
      </div>
      <br></br>
      <div className="input-container w-100">
        <label className="label fs-5 mb-2">Email: </label>
        <input
          type="email"
          name="email"
          className="input form-control"
          placeholder="Please Enter Your Email"
          required
          value={email}
          onChange={(e) => handleEmail(e.target.value)}
        />
      </div>

      <button
        type="submit"
        className=" d-block py-2 text-white mt-4 w-10 mb-3 mx-auto px-4 border-0 resetpwd"
        onClick={handleForgotPassword}
        style={{ backgroundColor: "#990000" }}
        disabled={isLoading ? true : false}
      >
        {isLoading ? "Loading..." : "Reset Password"}
      </button>
      {/* <p className="text-center cursor-pointer" style={{cursor:"pointer"}} onClick={handleLogin}>
        <MdOutlineKeyboardArrowLeft className="fs-3"/>Back to login</p> */}
      <ToastContainer />
    </div>
  );
};

export default ForgotPassword;
