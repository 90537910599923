import React, { useEffect, useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./News.css";
import ExportApi from "../../api/ExportApi";
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';



function News() {
    const [newsList, setNewsList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [clickCount, setClickCount] = useState(0);
    const itemsPerPage = 12;
    const [keyword, setKeyword] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const navigate = useNavigate();

    const fetchNewsList = async () => {
        try {
            setLoading(true);
            const response = await ExportApi.getAllnews(); // Assuming ExportApi.getAllNews() fetches all news data including images
            setLoading(false);
            setNewsList(response.data.data);
        } catch (error) {
            console.error("Error fetching news list:", error);
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchNewsList();
    }, []);

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
      };
    
      const pageCount = Math.ceil(newsList.length / itemsPerPage);
    
      const indexOfLastItem = (currentPage + 1) * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    
    
      const currentItems = newsList.slice(indexOfFirstItem, indexOfLastItem);

      let startTime = new Date();
    const handleClick = async (newsItemId) => {
        console.log("hit this up");
        try {
            navigate(`/news/${newsItemId}`);
            
            const endTime = new Date(); 
            const timeDifference = endTime - startTime; 
            const averageTime = timeDifference / (clickCount + 1); // Increment clickCount

            const averageTimeInMinutes = averageTime / 60000;  
            console.log(averageTimeInMinutes,"averageTimeInMinutes");
            const averageTimeInSeconds = averageTimeInMinutes * 60;
            console.log(averageTimeInSeconds,"averageTimeInSeconds");
            
            const averageTimeRounded = averageTimeInSeconds.toFixed(2);
            const averageTimes = parseFloat(averageTimeRounded); 
            const data = {
                averageTime: averageTimes,
                totalCount: clickCount + 1 
            };

             console.log(newsItemId,"newsItemId11");
             console.log(data,"data321");


            const response = await ExportApi.totalcount(newsItemId, data);
             
            if (response.ok) {
                console.log('Data posted successfully');
            } else {
                // Handle API errors
                console.error('Failed to post data:', response.statusText);
            }
            
            setClickCount(prevCount => prevCount + 1); // Update clickCount
        } catch (error) {
            console.error('Error:', error);
        }
    };
    
    useEffect(() => {
        const handleSearch = async () => {
            try {
                const response = await ExportApi.newssearch({ keyword });
                console.log(response.data.data, "response.data");
                setSearchResults(response.data.data);
            } catch (error) {
                console.error('Error searching for news:', error);
            }
        };

        const delayDebounceFn = setTimeout(() => {
            if (keyword) {
                handleSearch();
            } else {
                setSearchResults([]); // Reset searchResults to an empty array when keyword is empty
            }
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [keyword]);

    return (
        <>
            <Header />

            {loading ? <div className="loader-icon" style={{ marginBlock: "80px" }}>
                <i className="fa-solid fa-spinner fa-spin-pulse"></i>
            </div> : <>
                <div className='search-bar py-5' style={{ background: "#f9fafb" }}>
                    <Container>
                    <>
                            <Row>

                                <div className='search'>

                                    <div className="input-group">
                                        <span className="input-group-text" id="basic-addon1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="bi bi-search" viewBox="0 0 16 16">
                                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                                            </svg>
                                        </span>
                                        <input
                                            placeholder="Search News"
                                            autoComplete="false"
                                            type="text"
                                            className="form-control"
                                            value={keyword}
                                            onChange={(e) => setKeyword(e.target.value)}
                                        />
                                    </div>
                                    {searchResults.length > 0 ? (
                                        <div className="custom-news">

                                            {searchResults?.map((newsItem) => (

                                                <div className="custom-newsbar" key={newsItem?._id}>
                                                    <img
                                                        src={newsItem?.image}
                                                        alt={newsItem?.heading}
                                                        style={{ width: '100px', height: '100px', borderRadius: '5px' }}
                                                        onClick={() => handleClick(newsItem?._id)}
                                                    />
                                                    <div className="" onClick={() => handleClick(newsItem?._id)} style={{ cursor: 'pointer' }}>
                                                        <h3>{newsItem?.heading}</h3>
                                                        <p dangerouslySetInnerHTML={{ __html: newsItem?.description.replace(/<img[^>]*>|<\/?strong[^>]*>|<\/?a[^>]*>/g, '') }} />


                                                            {/* {newsItem?.description}
                                                        
                                                        </p> */}
                                                    </div>

                                                </div>

                                            ))}

                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                            </Row>
                        </>
                    </Container>
                </div>
                <div className="News-section py-5">
                    <Container>
                        <Row>
                            <>
                                {newsList.length > 0 ? (
                                    <>
                                        <Row>
                                            {currentItems.map((newsItem, index) => (
                                                <Col key={index} xs={12} md={4}>
                                                    <div className="news-grid" 
                                                    // onClick={() => navigate(`/news/${newsItem._id}`)}
                                                    onClick={() => handleClick(newsItem?._id)}
                                                    >
                                                        <img className="w-100 mb-3"    style={{ width: '300px', height: '300px'}} src={newsItem.image} alt={newsItem.heading} />
                                                        <h3 className="mb-3 text-dark fw-bold fs-5">{newsItem.heading}</h3>
                                                        <p className="fs-6 fw-normal"
                                                         dangerouslySetInnerHTML={{ __html: newsItem?.description.replace(/<h[^>]*>|<img[^>]*>|<\/?strong[^>]*>|<\/?a[^>]*>/g, '') }} />
                                                            
                                                            {/* {newsItem.description}</p> */}
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>

                                        {newsList.length > 9 ?
                                        <div className="d-flex align-items-center justify-content-end">
                                        <ReactPaginate
                                            nextLabel="Next >"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={3}
                                            marginPagesDisplayed={2}
                                            pageCount={pageCount}
                                            previousLabel="< Previous"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            renderOnZeroPageCount={null}
                                        />
                                        </div> : <div></div>}
                                    </>
                                ) : (
                                    <div className="loader-icon" style={{ marginBlock: "80px" }}>
                                        <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                                    </div>
                                )}
                            </>

                        </Row>
                    </Container>
                </div>
            </>

            }




            <Footer />
        </>
    )
}

export default News
