import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './bannerCarousel.css';

function BannerCarousel({ bannerImages, onClick }) {

  // Carousel Settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <button className="slick-prev">Previous</button>,
    nextArrow: <button className="slick-next">Next</button>
  };

  return (
    <div className=' market cs_ratio'>

      {bannerImages ? (<Slider {...settings}
        spaceBetween={30}
        slidesPerView={1}
        adaptiveHeight={true}
        navigation
        pagination={{ clickable: true }}>
        {bannerImages?.map((img, i) => {
          return (
            <div key={i} onClick={(e) => { onClick(e, bannerImages[i]?._id, i) }}>
              <img src={img.imageName} className='w-100' alt="" />
            </div>
          )
        })}
      </Slider>) : (<div className='custom-loader'>
        <svg
          version="1.1"
          id="L9"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          enableBackground="new 0 0 0 0"
          xmlSpace="preserve"
        >
          <path
            fill="blue"
            d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
          >
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur="1s"
              from="0 50 50"
              to="360 50 50"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      </div>)}

    </div>
  );
}
export default BannerCarousel;