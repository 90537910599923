import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import ExportApi from "../../api/ExportApi";
import { toast } from "react-toastify";

export default function SellerOrder() {
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [fee_percentage_cancelled, setfee_percentage_cancelled] = useState('');
  const [fee_percentage_failedToShip, setfee_percentage_failedToShip] = useState('');
  const [fee_percentage_authenticationFailed, setfee_percentage_authenticationFailed] = useState('');

  const onSubmit = async () => {
    try {
      setButtonDisabled(true);
      const requestData = {
        fee_percentage_cancelled,
        fee_percentage_failedToShip,
        fee_percentage_authenticationFailed,
      };

      const resp = await ExportApi.addSellerOrderCancelfee(requestData);
      if (resp?.data?.Status === true) {
        toast.success(resp.data.message);
        fetchData();
      } else {
        toast.error(resp.data.message);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setButtonDisabled(false);
    }
  };

  const fetchData = async () => {
    try {
      const res = await ExportApi.getSellerOrderCancelfee();
      const data = res?.data?.detail;

      setfee_percentage_cancelled(data?.fee_percentage_cancelled);
      setfee_percentage_failedToShip(data?.fee_percentage_failedToShip);
      setfee_percentage_authenticationFailed(data?.fee_percentage_authenticationFailed);
    } catch (error) {
      console.error('Error fetching seller order cancel fee data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="loader-icon" style={{ marginBlock: "80px" }}>
          <i className="fa-solid fa-spinner fa-spin-pulse"></i>
        </div>
      ) : (
        <div className="py-5">
          <p className="mb-4">
            If the order is cancelled by the seller, then there will be a processing fee of 10% of the original sale price.
          </p>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Enter percentage"
              value={fee_percentage_cancelled || ""}
              onChange={(e) => setfee_percentage_cancelled(e.target.value)}
            />
          </div>

          <p className="mb-4">If the shipment has lapsed, processing fees will be 15% of the original sale price.</p>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Enter percentage"
              value={fee_percentage_failedToShip || ""}
              onChange={(e) => setfee_percentage_failedToShip(e.target.value)}
            />
          </div>

          <p className="mb-4">If authentication fails, then processing fees will be 15% of the original sale price.</p>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Enter percentage"
              value={fee_percentage_authenticationFailed || ""}
              onChange={(e) => setfee_percentage_authenticationFailed(e.target.value)}
            />
          </div>

          <Button onClick={onSubmit} variant="primary" type="submit" className="mt-2" disabled={isButtonDisabled}>
            {isButtonDisabled ? 'Submitting...' : 'Submit'}
          </Button>
        </div>
      )}
    </>
  );
}
