import React from "react";
import { useEffect } from "react";
import { Table, Form, Modal, Button, Row, Col } from "react-bootstrap";
import ExportApi from "../api/ExportApi";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { jsPDF } from "jspdf";
import PendingSingleTable from "./PendingSingleTable";
import FilterModal from "./FilterModal";
import axios from "axios";
import OutsideClickHandler from "react-outside-click-handler";

const PendingTable = () => {
  const navigate = useNavigate();
  let imageUrl = process.env.REACT_APP_IMAGEBASEURL
  const [logineduser, setLogineduser] = useState(null);
  const [Data, setData] = useState();
  const [Data2, setData2] = useState();
  const [show, setShow] = useState();
  const [userId, setUserId] = useState();
  const [sellerName, setSellerName] = useState();
  const [sellerAddress, setSellerAddress] = useState();
  const [sellerPhone, setSellerPhone] = useState();
  const [sellerZipCode, setSellerZipCode] = useState();
  const [sellerState, setSellerState] = useState();
  const [sellerCity, setSellerCity] = useState();
  const [orderId, setOrderId] = useState();
  const [buyerName, setBuyerName] = useState("Skewws llc");
  const [buyerAddress, setBuyerAddress] = useState(
    "CHANDLER J GILBERT 2089 BAKER ST  LONDON W1U 6RN  ENGLAND"
  );
  const [buyerPhone, setBuyerPhone] = useState("01632960000");
  const [buyerZipCode, setBuyerZipCode] = useState("99999");
  const [buyerCity, setBuyerCity] = useState("Hougang");
  const [selectData, setSelectData] = useState();
  const [buyerState, setBuyerState] = useState("Central Region");
  const [orderCreateDate, setOrderCreateDate] = useState();
  const [buyerBid, setBuyerBid] = useState();
  const [buyerSalesTax, setBuyerSalesTax] = useState();
  const [buyerSubTotal, setBuyerSubTotal] = useState();
  const [buyerProductName, setBuyerProductName] = useState();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [buyerSku, setBuyerSku] = useState();
  const [currentSortKey, setCurrentSortKey] = useState(null);
  const [currentSortOrder, setCurrentSortOrder] = useState(1);
  const [res, setres] = useState();
  const [buyerProductCondition, setBuyerProductCondition] = useState();
  const [descriptionindex, setdescriptionindex] = useState();
  const [productId, setProductId] = useState();
  const [productStatus, setProductStatus] = useState();
  const [showBuyerData, setShowBuyerData] = useState(true);
  const [buyOrder, setBuyOrder] = useState([]);
  const [sellerOrder, setSellerOrder] = useState([]);
  const dataToDisplay = showBuyerData ? Data2 : Data;
  const dataToDisplayText = showBuyerData ? "Data2" : "Data";
  const [position, setPosition] = useState({ top: 0, left: 55 });
  const [positionShipping, setPositionShipping] = useState({ top: 59, left: 137 });
  const [loading, setLoading] = useState(false);
  const orderData = showBuyerData ? buyOrder : sellerOrder

  const [filteredListData, setFilteredListData] = useState([]);
  const [filterList, setFilterList] = useState();
  const [selectStatusFilter, setSelectStatusFilter] = useState([])
  const [filteredBuyOrder, setFilteredBuyOrder] = useState(buyOrder);

  const [nbrand, setNbrand] = useState();
  const [nseries, setNseries] = useState();
 const [nmodel, setNmodel] = useState();
 const [norderid, setNorderid] = useState();
 

  const [filteredSellerOrder, setFilteredSellerOrder] = useState(sellerOrder);
  const [selectedFilters, setSelectedFilters] = useState({
    condition: [],
    chipset: [],
    brands: [],
    series: [],
    model: [],
  });
  //console.log(showBuyerData, "showBuyerData>>>>>");
  const statusOptions = [
    'Waiting on Seller',
    'Pending Inspection',
    'Ready to Ship',
    'Shipped Out',
  ];
  const token = localStorage.getItem("token");
  const [showFilterModel, setShowFilterModel] = useState(false);

  //console.log("dataToDisplayText>>>>>>>>>>>", dataToDisplayText);

  //console.log("orderData>>>>>>>>>>>>>buyer&seller", orderData);

  //  Sorting Table Data
  const getNestedProperty = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };
  
  const sortHere = (key) => {
    console.log(key, "sortHere32");
    const sortOrder = key === currentSortKey ? -currentSortOrder : 1;
  
    const sortedProducts = [...Data2].sort((a, b) => {
      const aValue = getNestedProperty(a, key);
      const bValue = getNestedProperty(b, key);
  
      if (aValue < bValue) return -1 * sortOrder;
      if (aValue > bValue) return 1 * sortOrder;
      return 0;
    });
    const sortedProduct1 = [...Data].sort((a, b) => {
      const aValue = getNestedProperty(a, key);
      const bValue = getNestedProperty(b, key);
  
      if (aValue < bValue) return -1 * sortOrder;
      if (aValue > bValue) return 1 * sortOrder;
      return 0;
    });
  
    console.log(sortedProducts, "sortedProducts11");
    setCurrentSortKey(key);
    setCurrentSortOrder(sortOrder);
    setData2(sortedProducts);
    setData(sortedProduct1);
  };
  

  const handleShippingModal = () => {
    setButtonLoader(true);
    if (selectData) {
      setShow(true);
      handleshippingData();
    } else {
      toast.error("Please Select Product First");
    }
  };

  const [alreadyCreatedShippingLabel, setAlreadyCreatedShippingLabel] = useState();

  const handleshippingData = () => {
    setButtonLoader(true);
    ExportApi.getShippingData(orderId)
      .then((resp) => {
        let data = resp.data.Data;
        console.log(data.productId.productname,"productname23")
        //console.log("data", data);
        setSellerName(data.sellerId.firstname);
        setSellerAddress(data.sellerId.shipping_address.street);
        setSellerCity(data.sellerId.shipping_address.city);
        setSellerState(data.sellerId.shipping_address.state);
        setSellerZipCode(data.sellerId.shipping_address.postal_code);
        setSellerPhone(data.sellerId.phone);
        setBuyerBid(data?.bidId?.bidAmount);
        setBuyerSalesTax(data?.bidId?.salesTax);
        setBuyerSubTotal(data?.bidId?.subTotal);
        setBuyerProductName(data.productId.productname);
        setButtonLoader(false);
        setBuyerSku(data?.productId?.sku);
        setNorderid(data?.orderId);
        setBuyerProductCondition(data?.productId?.type);
        setNbrand(data?.productId?.brand);
        setNseries(data?.productId?.series);
        setNmodel(data?.productId?.model);
       
        // setBuyerCondition(data?.productId)

        setBuyerAddress(resp?.data?.adminAddress?.shipping_address?.street);
        setBuyerCity(resp?.data?.adminAddress?.shipping_address?.city);
        setBuyerState(resp?.data?.adminAddress?.shipping_address?.state);
        setBuyerZipCode(resp?.data?.adminAddress?.shipping_address?.postal_code);
        // setOrderCreateDate(resp?.data?.adminAddress?.shipping_address?.createdAt);
        setAlreadyCreatedShippingLabel(data?.deliveryStatusId?.sellerShippingLabel);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // ,"Pending Inspection","Shipped Out"
  const handleShippingLabel = () => {
    setButtonLoader(true);
    if (alreadyCreatedShippingLabel == null) {
      ExportApi.updateShippingLabelData(
        userId,
        orderId,
        "Shipped From Seller",
        "Pending Inspection",
        "Shipped Out"
      )
        .then((resp) => {
          if (resp.data.message == "Label created") {
            toast.success("Shipped From Seller Successfully");
            DownloadPdf();
            setButtonLoader(false);
            handleClose();
            // window.open(resp.data.Data.postage_label.label_url, "_blank");
            
            if (localStorage.getItem("tokenuser")) {
              handlePendingData(JSON.parse(localStorage.getItem("tokenuser")).id);
              setButtonLoader(false);
            } else if (localStorage.getItem("admin")) {
              handlePendingData(JSON.parse(localStorage.getItem("admin")).id);
              setButtonLoader(false);
            } else {
              //console.log("No Data Found");
              setButtonLoader(false);
            }
          } else {
            toast.error(resp.data.message);
            handleClose();
            setButtonLoader(false);
            if (localStorage.getItem("tokenuser")) {
              handlePendingData(JSON.parse(localStorage.getItem("tokenuser")).id);
            } else if (localStorage.getItem("admin")) {
              handlePendingData(JSON.parse(localStorage.getItem("admin")).id);
            } else {
              console.log("No Data Found");
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setButtonLoader(false);
        });
    } else {
      setButtonLoader(false);
      // window.open(alreadyCreatedShippingLabel, "_blank");
      DownloadPdf();
    }
  };

  const DownloadPdf = () => {
    const doc = new jsPDF("p", "pt", "a4");
    const tableContent = document.getElementById("werkk").innerHTML;
    doc.html(tableContent, {
      callback: () => {
        doc.save("Product.pdf");
      },
    });
  };

  const handlePendingData = (id) => {
    setUserId(id);
    ExportApi.getPendingData(id)
      .then((resp) => {
        let data = resp.data.Data;
        //console.log("Pending Data>>>>>>>>>>>>>215", data);
        let SellerPendingData = data?.sellerOrderlist?.filter(
          (item) =>
            item?.deliveryStatusId?.deliveryStatusForSeller?.status ==
            "Ready to Ship" ||
            item?.deliveryStatusId?.deliveryStatusForSeller?.status ==
            "Shipped Out" ||
            item?.deliveryStatusId?.deliveryStatusForSeller?.status ==
            "Failed to Ship" ||
            item?.deliveryStatusId?.deliveryStatusForSeller?.status ==
            "Authentication Failed" ||
            item?.deliveryStatusId?.deliveryStatusForSeller?.status ==
            "Item Returned" ||
            item?.deliveryStatusId?.deliveryStatusForSeller?.status ==
            "Order Cancelled"
        );

        setData(SellerPendingData);

        //console.log("SellerPendingData>>>>>>>>>", SellerPendingData)
        setBuyOrder(data.buyer);
        setSellerOrder(data.sellerOrderlist);
        let BuyerPendingData = data?.buyerOrderlist?.filter(
          (item) =>
            item?.deliveryStatusId?.deliveryStatusForBuyer?.status ==
            "Waiting on Seller" ||
            item?.deliveryStatusId?.deliveryStatusForBuyer?.status ==
            "Pending Inspection" ||
            item?.deliveryStatusId?.deliveryStatusForBuyer?.status ==
            "Shipped Out" ||
            // item?.deliveryStatusId?.deliveryStatusForBuyer?.status ==
            // "Refunded" ||
            item?.deliveryStatusId?.deliveryStatusForBuyer?.status ==
            "Payment Failed" ||
            item?.deliveryStatusId?.deliveryStatusForBuyer?.status ==
            "Placed With New Seller"

        );
        //console.log(BuyerPendingData, "BuyerPendingData>>>>>>>>>>");
        setData2(BuyerPendingData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    setShow(false);
  };

  // console.log(Data)
  const [handleShipStatusButtonColor, setHandleShipStatusButtonColor] = useState(false);
  const [unfilteredData, setUnfilteredData] = useState('');
  const [noDataBuyer, setNoDataBuyer] = useState('');
  const handleshipStatus = () => {
    if (!handleShipStatusButtonColor) {
      setUnfilteredData(Data);
      setNoDataBuyer(Data2);

      let filteredData = Data?.filter((item) => item.deliveryStatusId?.deliveryStatusForSeller
        ?.status == "Ready to Ship");
      setData(filteredData);
      setData2([]);
    } else {
      setData(unfilteredData);
      setData2(noDataBuyer);
    }
    // 👇️ toggle isActive state on click
    setHandleShipStatusButtonColor(current => !current);
  };

  const getPackageBuyerAddress = () => {
    ExportApi.getPackageData()
      .then((resp) => {
        let data = resp?.data?.result[0]?.shipping_address;
        setBuyerAddress(data.street);
        setBuyerCity(data.city);
        setBuyerState(data.state);
        setBuyerZipCode(data.postal_code);
        setOrderCreateDate(data?.createdAt);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //get Filter Data
  const GetData1 = () => {
    ExportApi.GetFilterData()
      .then((resp) => {
        let chipsetKeyArray = [];
        let conditionKeyArray = [];
        let brandKeyArray = [];
        let modalKeyArray = [];
        let seriesKeyArray = [];
        setres(resp?.data.data);
        resp?.data.data?.chipset?.map((val, i) => {
          let key = { [val]: false };
          chipsetKeyArray.push(key);
        });
        setchipsetKey([...chipsetKeyArray]);
        resp?.data.data?.condition?.map((val) => {
          if (val == 1) {
            let key = { ["New-Retail"]: false };
            conditionKeyArray.push(key);
          } else if (val == 2) {
            let key = { ["New-2nd Hand"]: false };
            conditionKeyArray.push(key);
          } else {
            let key = { ["Used"]: false };
            conditionKeyArray.push(key);
          }
        });
        setconditionKey([...conditionKeyArray]);
        resp?.data.data?.series?.map((val) => {
          let key = { [val]: false };
          seriesKeyArray.push(key);
        });
        setseriesKey([...seriesKeyArray]);
        resp?.data.data?.brands?.map((val) => {
          let key = { [val]: false };

          brandKeyArray.push(key);
        });
        setbrandKey([...brandKeyArray]);
        resp?.data.data?.model?.map((val) => {
          let key = { [val]: false };
          modalKeyArray.push(key);
        });
        setmodalKey([...modalKeyArray]);
        // setLoading(false);
        setFilterList(resp?.data?.data);
        //console.log(filterList);
      })
      .catch((err) => console.log(err));
  };

  const handleSingleUserData = (id) => {
    ExportApi.getSingleUserData(id)
      .then((resp) => {
        setLogineduser(resp.data.data._id)
        //console.log(resp.data.data._id, "useridd>354");
        if (resp.data.message == "user not found") {
          window.dispatchEvent(new Event("Loginout"));
        } else {
        }
      })
      .catch((err) => console.log(err));
  };

  window.addEventListener("Loginout", () => {
    localStorage.clear();
    navigate("/");
  });

  useEffect(() => {
    if (localStorage.getItem("tokenuser")) {
      handlePendingData(JSON.parse(localStorage.getItem("tokenuser")).id);
      handleSingleUserData(JSON.parse(localStorage.getItem("tokenuser")).id);
      GetData1();
    } else if (localStorage.getItem("admin")) {
      handlePendingData(JSON.parse(localStorage.getItem("admin")).id);
      handleSingleUserData(JSON.parse(localStorage.getItem("admin")).id);
      GetData1();
    } else {
      console.log("No Data Found");
    }
  }, []);

  useEffect(() => {
    getPackageBuyerAddress();
  }, []);


  // Get all filters
  // useEffect(() => {
  //   axios.get('https://api.gpuwiz.com/product/filterallKey', {
  //     params: {
  //       Authorization: `Bearer ${token}`
  //     },
  //   })
  //     .then(function (response) {

  //       setFilterList(response.data.data);

  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }, [])

  // clear All Filter
  const clearAllFilters = () => {
    setSelectStatusFilter([])
  };


  const hideModal = () => {
    setShowFilterModel(false);
  }

  const filteredOrderData = orderData?.filter((order) => {
    if (showBuyerData) {
      //console.log(showBuyerData, "showBuyerData>>>>>>>>>>>>");
      return (
        order?.deliveryStatusId?.deliveryStatusForBuyer?.status === selectStatusFilter
      );
    } else {
      return (
        order?.deliveryStatusId?.deliveryStatusForSeller?.status === selectStatusFilter
      );
    }
  });

  // Function to handle checkbox changes
  const handleCheckboxChange = (option) => {
    console.log(option, "options11");

    if (selectStatusFilter.includes(option)) {

      setSelectStatusFilter(selectStatusFilter.filter((item) => item !== option));

    } else {

      setSelectStatusFilter([...selectStatusFilter, option]);
    }
  };

  // Function to filter data based on selected filters
  const filterData = (data, filters, showBuyerData) => {
   
    return data?.filter((order) => {
      const status = showBuyerData
        ? order?.deliveryStatusId?.deliveryStatusForBuyer?.status
        : order?.deliveryStatusId?.deliveryStatusForSeller?.status;
      return filters.length === 0 || filters.includes(status);
    });
  };

  //console.log(filterData(orderData, selectStatusFilter, showBuyerData),"pending123");

  // Function to handle removing a filter
  const handleRemoveFilter = (filterValue) => {
    const updatedFilters = selectStatusFilter.filter(
      (filter) => filter !== filterValue
    );
    setSelectStatusFilter(updatedFilters);
  };

  const handleCheck = (data, id, orderid) => {
    // console.log("sdssd");
    // return
    if (data) {
      setOrderId(orderid);
      // console.log(Data);
      let product_id = orderid;
      let result = Data.filter((item) => item?._id == product_id);
      //console.log(result);
      setSelectData(result[0].productId?._id);
    } else {
      setSelectData();
      setOrderId();
    }

    
  };

  console.log(buyerProductName,'buyerProductName1')

  const spacedModel = nmodel ? nmodel.replace(/:/g, ' :').replace(/-/g, ' -') : '';
  const spacedbuyerSku = buyerSku ? buyerSku.replace(/:/g, ' :').replace(/-/g, ' -') : '';
  const spacedDate = nmodel ? nmodel.replace(/:/g, ' :').replace(/-/g, ' -') : '';
   
  return (
    <div>
      <div id="werkk" style={{ display: "none" }}>
        <div className="pdf-content">
          <div className="pdf-header">
            <span>GPUWIZ</span>
          </div>
          <hr />
          <div className="center-text m-30">
            <h2>Ship by {moment(orderCreateDate).format("MM/DD /YYYY")}</h2>
          </div>
          <hr />
          <div className="center-text m-30 order-condition">
            <h2>Order:&nbsp;{norderid}</h2>
            <p className="pname">
              {/* {buyerProductName} */}
              {nbrand}
            </p>
            <p className="pname">
              {nseries}
            </p>
            <p className="pname">
            {spacedModel.toLowerCase()}
            </p>
            <span>
              Condition:{" "}
              {buyerProductCondition?.type == 1
                ? "New"
                : buyerProductCondition?.type == 2
                  ? "2nd Hand"
                  : "Used"}
            </span>
            <span className="pname">MPN&nbsp;: {spacedbuyerSku?.toLowerCase()}</span>
          </div>
          <hr />
          <div className="m-30 shipping-instruction">
            <h4>Shipping Instructions:</h4>
            <ul style={{listStyleType:'decimal'}}>
              <li className="number">
                Ship your item right away to avoid fees and possibility to earn
                future discounts!
              </li>
              <li>
              Package &nbsp;your GPU
                <ul style={{listStyleType:'disc'}}>
                  <li>
                  If you are shipping a GPU &nbsp;without a box
                    <ul style={{listStyleType:'decimal'}}>
                      <li>
                      Wrap the GPU &nbsp;with Bubble wrap so there are no exposed
                        surfaces &nbsp;and all surfaces &nbsp;are covered. Wrap thickness
                         &nbsp;should be about 1 &nbsp;in on all sides. Place &nbsp;tape to secure
                         &nbsp;the wrap.
                      </li>
                      <li>
                        Place &nbsp;the wrapped GPU &nbsp;into an appropriately sized box.
                        Box should be sized so the wrapped GPU &nbsp;fits in without
                        force but is not loose in the box. Add packing peanuts
                        or other packaging material so the GPU &nbsp;cannot move
                        around in the box.
                      </li>
                    </ul>
                  </li>
                  <li>
                  If you are shipping a GPU &nbsp;with a box
                    <ul>
                      <li  style={{listStyleType:'disc'}}>Place &nbsp;the GPU &nbsp;box inside a larger shipping box.</li>
                      <li  style={{listStyleType:'disc'}}>
                        Add wrap, packing peanuts, or other shipping material to
                        make sure the GPU &nbsp;box is not loose to move around inside
                        the shipping box.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li >Place &nbsp;this invoice inside the box with the product.</li>
              <li>
              Properly tape and secure the shipping box using the H-method.
                Make sure to use shipping tape and not other forms of tape
                designed for other purposes.
              </li>
              <li>
                Tape &nbsp;shipping label on the exterior of the shipping box. You can
                also use a Carrier packet if available.
              </li>
              <li>
                Deliver the package to the shipping carrier.
                <ul>
                  <li style={{listStyleType:'disc'}}>
                  For UPS&nbsp;, you can drop off at a UPS &nbsp;location or schedule a
                    UPS &nbsp;pickup.
                  </li>
                  <li  style={{listStyleType:'disc'}}>
                    Make sure to check the carrier on your shipping label
                    matches your drop off carrier!
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              We are not responsible for items not being packaged properly. We
              are here to help, but it is on you to use your best judgment and
              get it done!
            </p>
          </div>
        </div>
      </div>

      <Row>
        <Col lg={12} className="mt-sm-5 mt-2">
          <div className="text-sm-end text-center">
            <Button
              className={`${handleShipStatusButtonColor ? 'bg-success' : 'bg-none'} ${handleShipStatusButtonColor ? 'text-white' : 'text-black'} border border-2 mb-2 mb-sm-0`}
              onClick={handleshipStatus}
            // disabled
            >
              Ready to ship
            </Button>
            <Button
              className="bg-none text-black border border-2 mx-2 mb-2 mb-sm-0"
              onClick={handleShippingModal}
            >
              Print shipping label
            </Button>
            <Button
              className="bg-none text-black border border-2 mb-2 mb-sm-0"
              onClick={() => setShowFilterModel(true)}
            >
              Filter
            </Button>
          </div>
        </Col>
        <Col
          lg={12}
          className="product_list_table table-responsive mt-sm-5 mt-2"
        >
          <>
            <div className="text-center text-sm-start d-flex">
              <Button
                className="rounded-0 bg-white border text-dark px-5 py-2 fw-bold"
                onClick={() => clearAllFilters()}
              >
                Clear all filters
              </Button>
              {selectStatusFilter?.length > 0 && (
                <div className="filters-list d-flex align-items-center">
                  {selectStatusFilter?.map((button, i) => {
                    return (
                      <div className="filter-button" key={i}>
                        <span className="bg-custom-light border px-3 py-2 text-center ms-2 fw-500 fs-6 mb-0 d-flex align-items-center">
                          {button}
                          <button
                            type="button"
                            className="bg-transparent border-0 py-0 text-dark btn btn-primary pe-0 mb-0"
                            onClick={() => handleRemoveFilter(button)}
                          >
                            X
                          </button>
                        </span>
                      </div>
                    );
                  })}
                </div>
              )}

            </div>

            <div className="data-list d-flex mt-4">
              <button className={`btn border me-3 ${showBuyerData ? 'active' : ''}`} onClick={() => setShowBuyerData(true)}>Buyer Data</button>
              {!localStorage.getItem("admin") ?
                <button className={`btn border ${showBuyerData ? '' : 'active'}`} onClick={() => setShowBuyerData(false)}>Seller Data</button>
                : ""}
            </div>

            <Table
              bordered
              striped
              className="align-middle text-center managment-table"
            >
              <thead>
                <tr>
                  <th className="text-start"></th>

                  <th onClick={() => sortHere("productId.chipset")}>
                    Chipset
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-chevron-expand"
                      viewBox="0 0 16 16"
                    >
                      <path
                        filerule="evenodd"
                        d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                      />
                    </svg>
                  </th>

                  <th onClick={() => sortHere("productId.brand")}>
                    Brand
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-chevron-expand"
                      viewBox="0 0 16 16"
                    >
                      <path
                        filerule="evenodd"
                        d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                      />
                    </svg>
                  </th>
                  <th onClick={() => sortHere("productId.series")}>
                    Series{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-chevron-expand"
                      viewBox="0 0 16 16"
                    >
                      <path
                        filerule="evenodd"
                        d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                      />
                    </svg>
                  </th>
                  <th onClick={() => sortHere("productId.model")}>
                    Model{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-chevron-expand"
                      viewBox="0 0 16 16"
                    >
                      <path
                        filerule="evenodd"
                        d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                      />
                    </svg>
                  </th>

                  <th onClick={() => sortHere("_id")}>
                    Order No.{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-chevron-expand"
                      viewBox="0 0 16 16"
                    >
                      <path
                        filerule="evenodd"
                        d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                      />
                    </svg>
                  </th>
                  <th onClick={() => sortHere("bidId.subTotal")}>
                    Status{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-chevron-expand"
                      viewBox="0 0 16 16"
                    >
                      <path
                        filerule="evenodd"
                        d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                      />
                    </svg>
                  </th>
                  <th onClick={() => sortHere("createdAt")}>
                    Order Date{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-chevron-expand"
                      viewBox="0 0 16 16"
                    >
                      <path
                        filerule="evenodd"
                        d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                      />
                    </svg>
                  </th>
                  <th onClick={() => sortHere("bidId.subTotal")}>
                    Price{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-chevron-expand"
                      viewBox="0 0 16 16"
                    >
                      <path
                        filerule="evenodd"
                        d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                      />
                    </svg>
                  </th>
                </tr>
              </thead>
              <tbody>
      
                {dataToDisplay?.length > 0 ? (

                  (selectStatusFilter.length > 0 ? filterData(dataToDisplay, selectStatusFilter, showBuyerData) :   filterData(dataToDisplay, selectStatusFilter, showBuyerData))?.map((item, index) => {

                    return (
                      item?.buyerId?._id == logineduser ||
                        item?.sellerId?._id == logineduser && item?.deliveryStatusId?.deliveryStatusForSeller?.status != "Order Cancelled" && item?.deliveryStatusId?.deliveryStatusForSeller?.status != "Failed to Ship" && item?.deliveryStatusId?.deliveryStatusForSeller?.status != "Authentication Failed" ?
                        <tr key={index}>
                          <td>
                            <div className="d-flex align-items-end position-relative">
                              {dataToDisplayText == "Data" ? (
                                <Form.Check
                                  className="d-inline"
                                  type="checkbox"
                                  checked={orderId == item._id ? true : false}
                                  onChange={(e) =>
                                    handleCheck(
                                      e.target.checked,
                                      item.productId?._id,
                                      item._id
                                    )
                                  }
                                />
                              ) : (
                                ""
                              )}
                              <img
                                src={`${item.productId?.image[0]}`}
                                style={{ width: "70px" }}
                                onClick={() =>
                                  navigate(`/product/${item.productId._id}`)
                                }
                              />
                              <div className="position-relative ms-1" style={{ cursor: "default" }}>
                                <span className="d-block text-center">
                                  {/* {item.productId?.type == 1
                                    ? "New"
                                    : item.productId?.type == 2
                                      ? "2nd Hand"
                                      : "Used"} */}
                                  {

                                    item.productId?.type == 2
                                      ? "New"
                                      : "Used"}
                                </span>

                                {descriptionindex == index ? (
                                  <span
                                    className="bg-none border-0"
                                    onClick={() => setdescriptionindex()}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="#0000ff"
                                      className="bi bi-chevron-down"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        filerule="evenodd"
                                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                      />
                                    </svg>
                                  </span>
                                ) : (
                                  <span
                                    className="bg-none border-0"
                                    onClick={(e) => { setdescriptionindex(index) }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="#0000ff"
                                      className="bi bi-chevron-down"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        filerule="evenodd"
                                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                      />
                                    </svg>
                                  </span>
                                )}
                                <OutsideClickHandler
                                  onOutsideClick={(e) => {
                                    if (!e.target.closest('.modal_custom_')) {
                                      setdescriptionindex();
                                      setProductId();
                                      setProductStatus();
                                    }
                                  }}
                                ></OutsideClickHandler>

                                {descriptionindex == index ? (
                                  <div className="modal_custom_ pending-mod" style={{
                                    top: `45px`,
                                    left: `10px`,
                                  }}>
                                    <div className="inner_customModal_one">
                                      <h6
                                        dangerouslySetInnerHTML={{
                                          __html: `${item?.productId?.description ? item?.productId?.description.substring(0, 351) : "No product description"}${item?.productId?.description?.length > 351 ? '...' : ''}`,
                                        }}
                                      ></h6>
                                      {item?.productId?.description?.length > 351 ?
                                        <a onClick={() =>
                                          navigate(`/product/${item.productId._id}`)
                                        }>See more</a> : ""}
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div style={{ cursor: "default" }} title={item.productId?.chipset} >
                              {item.productId?.chipset}
                            </div>
                          </td>
                          <td>
                            <div style={{ cursor: "default" }} title={item.productId?.brand} >
                              {item.productId?.brand}
                            </div>
                          </td>
                          <td>
                            <div style={{ cursor: "default" }} title={item.productId?.series} >
                              {item.productId?.series}
                            </div>
                          </td>
                          <td>
                            <div className="usermodel" title={item.productId?.model}>
                              {item.productId?.model}
                            </div>
                          </td>
                          {/* {item._id} */}
                          <td>
                            <div style={{ cursor: "default" }} title={item?.orderId} >
                              {item?.orderId}

                              {productId == index ? (
                                <Button
                                  className="bg-none border-0"
                                  onClick={() => setProductId()}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="#0000ff"
                                    className="bi bi-chevron-down"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      filerule="evenodd"
                                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                    />
                                  </svg>
                                </Button>
                              ) : (
                                <Button
                                  className="bg-none border-0"
                                  onClick={() => setProductId(index)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="#0000ff"
                                    className="bi bi-chevron-down"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      filerule="evenodd"
                                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                    />
                                  </svg>
                                </Button>
                              )}
                              {productId == index ? (
                                <div className="modal_custom_selling_price"> 
                                <div  >
                                  <div className="inner_customModal_two" style={{ padding: '10px' }}>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: `<div class="order-detail-list">
                                    <div class='d-flex justify-content-between'>
                                        <p class="me-3">Sale Price</p>
                                        <p>$${dataToDisplayText == "Data2" ? (
                                            item?.bidId?.bidAmount
                                          ) :
                                            (item?.askId?.askAmount)
                                          }</p>
                                    </div>
                                    ${dataToDisplayText == "Data" ? (
                                            `<div class='d-flex justify-content-between'>
                                            <p class="me-3">Transaction Fee</p>
                                            <p>$${item?.askId?.transactionFee}</p>
                                        </div>`
                                          ) : ""}
                                    <div class='d-flex justify-content-between'>
                                        <p class="me-3">${dataToDisplayText == "Data2" ? "Processing Fee" : "Payment Processing Fee"}</p>
                                        <p>$${dataToDisplayText == "Data2" ? (
                                            item?.bidId?.processingFee
                                          ) :
                                            (item?.askId?.processingFee)
                                          }</p>
                                    </div>
                                    <div class='d-flex justify-content-between'>
                                        <p class="me-3">Shipping</p>
                                        <p>$${dataToDisplayText == "Data2" ? (
                                            item?.bidId?.shippingFee
                                          ) :
                                            (item?.askId?.shippingFee)
                                          }
                                        </p>
                                    </div>
                                    ${dataToDisplayText == "Data2" ? (
                                            `<div class='d-flex justify-content-between'>
                                          <p class="me-3">Sales Tax</p>
                                          <p>$${item?.askId?.transactionFee}</p>
                                      </div>`
                                          ) : ""}
                                    <div class='d-flex justify-content-between fw-bold'>
                                        <p class="me-3">TOTAL</p>
                                        <div class="d-flex align-items-center">
                                        <p class="mb-0 me-1">$${dataToDisplayText == "Data2" ? (
                                            item?.bidId?.subTotal
                                          )
                                            :
                                            (item?.askId?.subTotal)
                                          }
                                      </p>
                                    ${item?.payment_details?.status == "Paid" ?
                                            // If payment status is "paid"
                                            `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#008000" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                                      </svg>`
                                            : item?.payment_details?.status == "failed" ?
                                              // If payment status is "failed"
                                              `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FF0000" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                                        </svg>`:
                                              // If payment status is neither "paid" nor "failed"
                                              `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 5L3 10L8 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M3 10H11C16.523 10 21 14.477 21 20V21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>`
                                          }
                                    </div>
                                    </div>
                                </div>
                                ${dataToDisplayText == "Data" ? (
                                            `<div class='d-flex justify-content-between'>
                                      <p class="me-3">Inspection Result</p>
                                      <p>${item?.deliveryStatusId.authStatus?.status}</p> &nbsp;
                                        ${item?.deliveryStatusId.authStatus?.status === "fail" ?
                                              item?.deliveryStatusId?.authStatus?.reason.length > 0 && item?.deliveryStatusId?.authStatus?.reason.map((reason, index) => (
                                                ` <p key={index}>${reason}test</p>`
                                              )
                                              ) : ""}
                                  </div>
                                  <div class='d-flex justify-content-between'>
                                      <p class="me-3">${item?.deliveryStatusId.authStatus?.comment ? "Comment" : ""}</p>                             
                                      <p>${item?.deliveryStatusId.authStatus?.comment ? item?.deliveryStatusId.authStatus?.comment : ""}</p>
                                  </div>`
                                          ) : ""}`

                                      }}></div>
                                  </div>
                                </div>
                                </div>
                              ) : null}
                            </div>
                          </td>

                          <td>
                            {
                              dataToDisplayText == "Data2" ? (
                                // item?.buyerId?._id != logineduser ? (
                                //   <Button style={{cursor:'not-allowed'}} className="order_status_newseller border text-white">
                                //      Placed With New Seller
                                //   </Button>) :
                                item?.deliveryStatusId?.deliveryStatusForBuyer
                                  ?.status == "Waiting on Seller" ? (
                                  <Button className="order_status_waiting border text-white">
                                    {
                                      item?.deliveryStatusId.deliveryStatusForBuyer
                                        ?.status
                                    }
                                  </Button>
                                ) : item?.deliveryStatusId?.deliveryStatusForBuyer
                                  ?.status == "Payment Failed" ? (
                                  <Button className="order_status_shipped_seller border text-white">
                                    {
                                      item?.deliveryStatusId.deliveryStatusForBuyer
                                        ?.status
                                    }
                                  </Button>
                                ) : item?.deliveryStatusId?.deliveryStatusForBuyer
                                  ?.status == "Pending Inspection" ? (
                                  <Button className="order_status_ready_test border text-white">
                                    {
                                      item?.deliveryStatusId?.deliveryStatusForBuyer
                                        ?.status
                                    }
                                  </Button>
                                ) : item?.deliveryStatusId?.deliveryStatusForBuyer
                                  ?.status == "Ready For Shipment" ? (
                                  <Button className="order_status_ready_shipment border text-white">
                                    {item?.deliveryStatusId?.deliveryStatus?.status}
                                  </Button>
                                ) : item?.deliveryStatusId?.deliveryStatusForBuyer
                                  ?.status == "Shipped Out" ? (
                                  <Button className="order_status_shipped_out border text-white">
                                    {
                                      item?.deliveryStatusId?.deliveryStatusForBuyer
                                        ?.status
                                    }
                                  </Button>
                                ) : item?.deliveryStatusId?.deliveryStatusForBuyer
                                  ?.status == "Delivered" ? (
                                  <Button className="order_status_recently_delivered border text-white">
                                    {
                                      item?.deliveryStatusId?.deliveryStatusForBuyer
                                        ?.status
                                    }
                                  </Button>
                                ) : item?.deliveryStatusId?.deliveryStatusForBuyer
                                  ?.status == "Placed With New Seller" ? (
                                  <Button className="order_status_newseller border text-white">
                                    {
                                      item?.deliveryStatusId?.deliveryStatusForBuyer
                                        ?.status
                                    }
                                  </Button>
                                ) : item?.deliveryStatusId?.deliveryStatusForBuyer
                                  ?.status == "Refunded" ? (
                                  <Button className="order_status_refunded border text-white">
                                    {
                                      item?.deliveryStatusId?.deliveryStatusForBuyer
                                        ?.status
                                    }
                                  </Button>
                                ) : item?.deliveryStatusId?.deliveryStatusForBuyer
                                  ?.status == "Order Cancelled" ? (
                                  <Button className="order_status_return border text-white">
                                    {
                                      item?.deliveryStatusId?.deliveryStatusForBuyer
                                        ?.status
                                    }
                                  </Button>
                                ) : item?.deliveryStatusId?.deliveryStatusForSeller?.status == "Failed to Ship" ? (
                                  <Button className="order_status_failed border text-white">
                                    {
                                      item?.deliveryStatusId?.deliveryStatusForSeller?.status
                                    }
                                  </Button>
                                ) :
                                  item?.deliveryStatusId?.deliveryStatusForSeller?.status == "Order Cancelled" ? (
                                    <Button className="order_status_refunded border text-white">
                                      {
                                        item?.deliveryStatusId?.deliveryStatusForSeller?.status
                                      }
                                    </Button>
                                  ) : (
                                    ""
                                  )
                              ) :
                                (

                                  item?.sellerId?._id != logineduser ? (
                                    item?.deliveryStatusId?.last_cancel_type == "2" ? (
                                      <Button style={{ cursor: 'not-allowed' }} className="order_status_failed_to_ship border text-white">
                                        Failed to Ship
                                      </Button>
                                    ) : item?.deliveryStatusId?.last_cancel_type == "3" ? (
                                      <Button style={{ cursor: 'not-allowed' }} className="order_status_failed_to_ship border text-white">
                                        Authentication Failed
                                      </Button>
                                    ) : (
                                      <Button style={{ cursor: 'not-allowed' }} className="order_status_refunded border text-white">
                                        Order Cancelled
                                      </Button>
                                    )
                                  ) : item?.deliveryStatusId?.deliveryStatusForSeller?.status == "Waiting on Seller" ? (
                                    <Button className="order_status_waiting border text-white">
                                      {
                                        item?.deliveryStatusId.deliveryStatusForSeller?.status
                                      }
                                    </Button>
                                  ) : item?.deliveryStatusId?.deliveryStatusForSeller?.status == "Payment Failed" ? (
                                    <Button className="order_status_shipped_seller border text-white">
                                      {
                                        item?.deliveryStatusId.deliveryStatusForSeller?.status
                                      }
                                    </Button>
                                  ) : item?.deliveryStatusId?.deliveryStatusForSeller?.status == "Pending Inspection" ? (
                                    <Button className="order_status_ready_test border text-white">
                                      {
                                        item?.deliveryStatusId?.deliveryStatusForSeller?.status
                                      }
                                    </Button>
                                  ) : item?.deliveryStatusId?.deliveryStatusForSeller?.status == "Ready to Ship" ? (
                                    <Button className="order_status_ready_shipment border text-white">
                                      {item?.deliveryStatusId?.deliveryStatusForSeller?.status}
                                    </Button>
                                  ) : item?.deliveryStatusId?.deliveryStatusForSeller?.status == "Shipped Out" ? (
                                    <Button className="order_status_shipped_out border text-white">
                                      {
                                        item?.deliveryStatusId?.deliveryStatusForSeller?.status
                                      }
                                    </Button>
                                  ) : item?.deliveryStatusId?.deliveryStatusForSeller?.status == "Delivered" ? (
                                    <Button className="order_status_recently_delivered border text-white">
                                      {
                                        item?.deliveryStatusId?.deliveryStatusForSeller?.status
                                      }
                                    </Button>
                                  ) : item?.deliveryStatusId?.deliveryStatusForSeller?.status == "Placed With New Seller" ? (
                                    <Button className="order_status_return border text-white">
                                      {
                                        item?.deliveryStatusId?.deliveryStatusForSeller?.status
                                      }
                                    </Button>
                                  ) : item?.deliveryStatusId?.deliveryStatusForSeller?.status == "Refunded" ? (
                                    <Button className="order_status_return border text-white">
                                      {
                                        item?.deliveryStatusId?.deliveryStatusForSeller?.status
                                      }
                                    </Button>
                                  ) : item?.deliveryStatusId?.deliveryStatusForSeller?.status == "Order Cancelled" ? (
                                    <Button className="order_status_cancelled border text-white">
                                      {
                                        item?.deliveryStatusId?.deliveryStatusForSeller?.status
                                      }
                                    </Button>
                                  ) : item?.deliveryStatusId?.deliveryStatusForSeller?.status == "Authentication Failed" ? (
                                    <Button className="order_status_failed_to_ship border text-white">
                                      {
                                        item?.deliveryStatusId?.deliveryStatusForSeller?.status
                                      }
                                    </Button>
                                  )
                                    : item?.deliveryStatusId?.deliveryStatusForSeller?.status == "Failed to Ship" ? (
                                      <Button className="order_status_failed_to_ship border text-white">
                                        {
                                          item?.deliveryStatusId?.deliveryStatusForSeller?.status
                                        }
                                      </Button>
                                    ) : ''
                                )
                            }
                            <span className="position-relative ms-2"> 
                            {productStatus == index ? (
                              <span
                                className=""
                                onClick={() => { setProductStatus(); showPostionTooltip() }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="#0000ff"
                                  className="bi bi-chevron-down"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    filerule="evenodd"
                                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                  />
                                </svg>
                              </span>
                            ) : (
                              <span
                                className=""
                                onClick={() => setProductStatus(index)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="#0000ff"
                                  className="bi bi-chevron-down"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    filerule="evenodd"
                                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                  />
                                </svg>
                              </span>
                            )}
                            {productStatus == index ? (
                              <div className="modal_custom_ pending-mod right-tooltip top-0 left-30"  
                              >
                                <div className="inner_customModal_one">
                                  <h6
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item?.sellerId?._id === logineduser || item?.buyerId?._id === logineduser
                                          ? (dataToDisplayText === "Data2"
                                            ? item?.deliveryStatusId?.deliveryStatusForBuyer?.status || "No description available"
                                            : item?.deliveryStatusId?.deliveryStatusForSeller?.status || "No description available")
                                          : (item?.deliveryStatusId?.last_cancel_type === "2"
                                            ? 'Failed to Ship'
                                            : item?.deliveryStatusId?.last_cancel_type === "3"
                                              ? 'Authentication Failed'
                                              : 'Order Cancelled'
                                          )
                                    }}
                                  ></h6>


                                </div>
                              </div>
                            ) : null}
                            </span>
                          </td>

                          <td>
                            <div style={{ cursor: "default" }} title={moment(item?.createdAt).format("l")} >
                              {moment(item?.createdAt).format("l")}
                            </div>
                          </td>
                          <td>
                            <div style={{ cursor: "default" }} title={"$" + item?.bidId?.subTotal} >
                              {"$" + item?.bidId?.subTotal}
                              {console.log(item?.bidId?.subTotal,"subtotal22")}
                            </div>
                          </td>
                        </tr> : ""
                    )
                  })
                ) : (
                  <span>{showBuyerData ? 'No Buyer Data Found' : 'No Seller Data Found'}</span>
                )}
              </tbody>
              {/* <tbody>
                {dataToDisplay?.length > 0 ? (
                  <PendingSingleTable Data2={selectStatusFilter.length > 0 ? filterData(orderData, selectStatusFilter, showBuyerData) : dataToDisplay} imageUrl={imageUrl} setdescriptionindex={setdescriptionindex} descriptionindex={descriptionindex} productId={productId} setProductId={setProductId} itemId={orderData} showBuyerData={showBuyerData} dataToDisplayText={dataToDisplayText} productStatus={productStatus} setProductStatus={setProductStatus} />
                ) : (
                  <span>{showBuyerData ? 'No Buyer Data Found' : 'No Seller Data Found'}</span>
                )}
              </tbody> */}
            </Table>
          </>
        </Col>
      </Row>

      {/* start print label */}
      <Modal show={show} onHide={handleClose} backdrop={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="my-3 ">
              Ship From
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollable-modal-body">

          {alreadyCreatedShippingLabel == null ?
            <Form>
              <div className="px-2">
                <Form.Group
                  className="mb-2 d-flex align-items-center gap-2"
                  controlId="formBasicEmail"
                >
                  <Form.Label className="fs-6 fw-bold mb-0">Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Name"
                    // value={buyerName}
                    value={sellerName}
                  // onChange={(e) => setSellerName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-2 d-flex align-items-center gap-2"
                  controlId="formBasicEmail"
                >
                  <Form.Label className="fs-6 fw-bold mb-0">Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Address"
                    // value={buyerAddress}
                    value={sellerAddress}
                  // onChange={(e) => setSellerAddress(e.target.value)}
                  />
                </Form.Group>
                <div className="d-sm-flex justify-content-start gap-2">
                  <Form.Group
                    className="mb-2 d-flex align-items-center gap-2"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="fs-6 fw-bold mb-0">City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Your City"
                      // value={buyerCity}
                      value={sellerCity}
                    // onChange={(e) => setSellerCity(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-2 d-flex align-items-center gap-2"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="fs-6 fw-bold mb-0 ">State</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Your State"
                      // value={buyerState}
                      value={sellerState}
                    // onChange={(e) => setSellerState(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-2 d-flex align-items-center gap-2"
                    controlId="formBasicEmail"
                  >
                    <Form.Label
                      className="fs-6 fw-bold mb-0
                      "
                    >
                      Zip Code
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Your Zip Code"
                      // value={buyerZipCode}
                      value={sellerZipCode}
                    // onChange={(e) => setSellerZipCode(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <Form.Group
                  className="mb-2 d-flex align-items-center gap-2"
                  controlId="formBasicEmail"
                >
                  <Form.Label className="fs-6 fw-bold mb-0">Phone</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Your Phone No."
                    // value={buyerPhone}
                    value={sellerPhone}
                  // onChange={(e) => setSellerPhone(e.target.value)}
                  />
                </Form.Group>
              </div>
              <h5 className="my-3 ">
                Ship To
              </h5>
              <div className="px-2">
                <Form.Group
                  className="mb-2 d-flex align-items-center gap-2"
                  controlId="formBasicEmail"
                >
                  <Form.Label className="fs-6 fw-bold mb-0">Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Name"
                    // value={sellerName}
                    value={buyerName}
                  // onChange={(e) => setBuyerName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-2 d-flex align-items-center gap-2"
                  controlId="formBasicEmail"
                >
                  <Form.Label className="fs-6 fw-bold mb-0">Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Address"
                    // value={sellerAddress}
                    value={buyerAddress}
                  // onChange={(e) => setBuyerAddress(e.target.value)}
                  />
                </Form.Group>
                <div className="d-sm-flex justify-content-between gap-2">
                  <Form.Group
                    className="mb-2 d-flex align-items-center gap-2"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="fs-6 fw-bold mb-0">City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Your City"
                      // value={sellerAddress}
                      value={buyerCity}
                    // onChange={(e) => setBuyerCity(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-2 d-flex align-items-center gap-2"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="fs-6 fw-bold mb-0">State</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Your State"
                      // value={sellerState}
                      value={buyerState}
                    // onChange={(e) => setBuyerState(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-2 d-flex align-items-center gap-2"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="fs-6 fw-bold mb-0">
                      Zip Code
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Your Zip Code"
                      // value={sellerZipCode}
                      value={buyerZipCode}
                    // onChange={(e) => setBuyerZipCode(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <Form.Group
                  className="mb-2 d-flex align-items-center gap-2"
                  controlId="formBasicEmail"
                >
                  <Form.Label className="fs-6 fw-bold mb-0">Phone</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Your Phone"
                    // value={sellerPhone}
                    value={buyerPhone}
                  // onChange={(e) => setBuyerPhone(e.target.value)}
                  />
                </Form.Group>
              </div>
            </Form>
            :
            <h3>Shipping Label Already Created !!!</h3>}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            className="d-block w-100"
            onClick={handleShippingLabel}
            disabled={buttonLoader}
          >
            {buttonLoader ? "Please wait" : "Print Label"}
          </Button>
        </Modal.Footer>
      </Modal>
      {/*end print label */}

      {/* start Filter Model */}
      <Modal
        show={showFilterModel}
        onHide={hideModal}
        backdrop={true}

      >
        <Modal.Header closeButton>
          <Modal.Title>Product Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollable-modal-body">

          <div className="mt-sm-5 mt-3 mb-sm-5">
            {statusOptions.map((option, index) => (
              <div className="mb-2" key={index}>
                <input
                  type="checkbox"
                  value={option}
                  checked={selectStatusFilter.includes(option)}
                  onChange={() => handleCheckboxChange(option)}
                  className='form-check-input'
                />
                <label key={option} className='form-check-label ms-2 mb-0'>{option}</label>
              </div>

            ))}
          </div>
        </Modal.Body>
      </Modal>
      {/* End Filter Model */}
    </div>
  );
};

export default PendingTable;