import React from "react";

const Blanck = () => {
  return (
    <div>
      <img
        style={{ width: "100%", height: "100vh" }}
        src="https://static.vecteezy.com/system/resources/previews/004/243/615/original/creative-coming-soon-teaser-background-free-vector.jpg"
      />
    </div>
  );
};

export default Blanck;
