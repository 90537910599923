import React from "react";
import "./About.css";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Imgpone from "../../../src/images/bg-1.jpg";
import Imgptwo from "../../../src/images/b1.png";
import Imgpthree from "../../../src/images/b2.png";
import Imgpfour from "../../../src/images/b3.png";
import Imgpfive from "../../../src/images/b4.jpg";
import Imgpsix from "../../../src/images/b5.png";
import Imgpseven from "../../../src/images/b7.jpg";
import Imgpeight from "../../../src/images/b8.jpg";

const MobileView = () => {
 
  return (
    <>
        <Header />
        <div className="">

          <div id="section1" className="secs" data-aos="fade-up">
            <div className="container">
              <div className="row">
              <div className="col-lg-12">
                <img src={Imgpone} alt="" className="img-fluid" />
              </div>
                <div className="col-md-12 text-center">
                  <h2 className="fw-bold mb-4 text-white" data-aos="zoom-in">Used GPU Market</h2>
                  <p className="text-white" data-aos="zoom-in">
                    Buying a used GPU can be a nightmare.
                  </p>
                  <p className="text-white" data-aos="zoom-in">
                    There are hundreds of options to chose from and they all vary in performance.
                  </p>
                  <p className="text-white">Even if you know exactly what model you want, when you type it into a search you still get hundreds of options.</p>
                  <p className="text-white">Finding the best price, assessing the condition, and finding a seller you can trust can be a time consuming and difficult process.</p>
                  <p className="text-white">It should not be like this!</p>
                </div>                
              </div>
            </div>
          </div>
          <div id="section2" className="secs" data-aos="fade-up">
            <div className="container">
              <div className="row">
              <div className="col-lg-12">
                <img src={Imgptwo} alt="" className="img-fluid" />
              </div>
                <div className=" col-md-12 text-center">
                  <h2 className="fw-bold mb-4" data-aos="zoom-in">Buying on GPUwiz</h2>
                  <p className="fw-bold" data-aos="zoom-in">
                    We make buying used graphics cards easy!
                  </p>
                  <p data-aos="zoom-in">
                    Buying a used GPU should be as simple as selecting the model
                    you want and clicking "Buy Now", knowing you are getting a
                    product that is in excellent condition at a fair market price.
                  </p>
                  <p className="fw-bold" data-aos="zoom-in">This is what we make possible!</p>
                </div>
                <div className="col-lg-8 col-md-12"></div>
              </div>
            </div>
          </div>
          <div id="section3" className="secs" data-aos="fade-up">
            <div className="container">
              <div className="row">
              <div className="col-lg-12">
                <img src={Imgpthree} alt="" className="img-fluid" />
              </div>
                <div className="col-lg-6 col-md-12 mx-auto text-center">
                  <h2 className="fw-bold text-white mb-4">How?</h2>
                  <p className="text-white">
                    We make sure every used GPU meets our quality standards
                    through physical inspection and testing!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="section4" className="secs" data-aos="fade-up">
            <div className="container">
              <div className="row">
              <div className="col-lg-12">
                <img src={Imgpfour} alt="" className="img-fluid" />
              </div>
                <div className="col-lg-6 col-md-12 mx-auto text-center">
                  <h2 className="fw-bold text-white mb-4">How?</h2>
                  <p className="text-white">
                    Similar to the stock market, we let users set the price so you
                    are buying the lowest cost option.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="section5" className="secs" data-aos="fade-up">
            <div className="container">
              <div className="row">
              <div className="col-lg-12">
                <img src={Imgpfive} alt="" className="img-fluid" />
              </div>
                <div className="col-lg-5 col-md-12 text-center">
                  <h2 className="fw-bold mb-4">Downside for Buyers?</h2>
                  <p>
                    Shipping can take longer as we need to receive and test every
                    GPU.
                  </p>
                  <p>
                    All purchases are final and there are no returns offered as
                    this a live marketplace for used items.
                  </p>
                  <p>
                    Similar to the stock market, when you buy a stock you cannot
                    return it, but you are free to sell it at anytime.
                  </p>
                </div>
                <div className="col-lg-7 col-md-12"></div>
              </div>
            </div>
          </div>
          <div id="section6" className="secs" data-aos="fade-up">
            <div className="container">
              <div className="row">
              <div className="col-lg-12">
                <img src={Imgpsix} alt="" className="img-fluid" />
              </div>
                <div className="col-lg-6 col-md-12 mx-auto text-center">
                  <h2 className="fw-bold mb-4">Selling on GPUwiz</h2>
                  <p className="fw-bold">No pictures and descriptions!</p>
                  <p>
                    Taking pictures and creating a listing with descriptions is
                    time consuming and unnecessary.
                  </p>
                  <p>
                    We do not want you wasting your time, instead, its more
                    important to verify the graphics cards are in good condition
                    by physical inspection and benchmarking.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div id="section7" className="secs" data-aos="fade-up">
            <div className="container">
              <div className="row">
              <div className="col-lg-12">
                <img src={Imgpseven} alt="" className="img-fluid" />
              </div>
                <div className="col-lg-5 col-md-12 mx-auto text-center">
                  <h2 className="fw-bold mb-4 text-white">
                    Selling on GPUwiz
                  </h2>
                  <p className="text-white">
                    Sell instantly and save time!
                  </p>
                  <p className="text-white">
                    Pricing your graphics card can be time consuming. You have to research previous sales and current listings to price your GPU. You also have to wait for your item to sell and communicate with buyers.
                  </p>
                  <p className="text-white">
                    Not anymore, we tell you exactly what the live market price is so you can simply chose if you want to be the lowest priced on the market or you can sell instantly to the highest bidder.
                  </p>
                </div>
                <div className="col-lg-7 col-md-12"></div>
              </div>
            </div>
          </div>
          <div id="section8" className="secs" data-aos="fade-up">
            <div className="container">
              <div className="row">
              <div className="col-lg-12">
                <img src={Imgpeight} alt="" className="img-fluid" />
              </div>
                <div className="col-lg-6 col-md-12 mx-auto text-center">
                  <div className="">
                    <h2 className="fw-bold mb-4 text-white">
                      Downside for Sellers?
                    </h2>
                    <p className="text-white">
                      You can only sell GPUs that meet our Quality Standards.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        
    </>
  );
};

export default MobileView;
