import Modal from 'react-bootstrap/Modal';

function ShippingModal({show, handleClose, data, description, setDescription, handleShippingEdit}) {
    console.log("description", description)
  return (
    <div
      className="modal show"
      style={{ display: 'block', position: 'initial' }}
    >
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Details</Modal.Title>
        </Modal.Header>
{/* 
        <Modal.Body className='shipping-modal'> */}
        <Modal.Body className="scrollable-modal-body">
            <form action="">
                <div className="input-container my-4">
                    <label>Description</label>
                    <input type="text" className="form-control" placeholder={data?.description} value={description?.length > 0 ? description : data?.description} onChange={(e) => {setDescription(e.target.value)}} />
                </div>
            </form>
            <div className="buttons d-flex justify-content-center mt-4">
                <button className='btn btn-primary me-4' onClick={(e) => {handleShippingEdit(e, data._id, description?.length > 0 ? description : data?.description)}}>Edit</button>
                <button className='btn btn-danger' onClick={() => {handleClose()}}>Cancel</button>
            </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ShippingModal;